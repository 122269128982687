export enum TransType {
  byBoiler,
  byFSK,
}

export enum TransTableType {
  oneParts,
  twoParts,
}

export enum TransFSKTableType {
  networks,
  losses,
}
