import { ReactElement, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'

import { CARDS_DATA } from './MenuData'

interface NavBarProps {
  onClose: () => void
}

interface Links {
  name: string
  url: string
}

interface CardProps {
  heading: string
  icon: ReactElement
  links: Links[]
  description?: string
  onClose: () => void
}

const Card: React.FC<CardProps> = ({
  heading,
  description,
  icon,
  links,
  onClose,
}) => {
  const navigate = useNavigate()

  const handleLinkClick = useCallback(
    (url: string, event: React.MouseEvent) => {
      if (event.metaKey || event.ctrlKey || event.button === 1) {
        return
      }
      event.preventDefault()
      navigate(url)
      onClose()
    },
    [navigate, onClose],
  )

  return (
    <Box
      maxW={{ base: 'full', md: '300px' }}
      w={'full'}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={3}
    >
      <Stack align={'center'} spacing={1}>
        <Flex
          w={12}
          h={12}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={useColorModeValue('gray.100', 'gray.700')}
        >
          {icon}
        </Flex>
        <Box mt={1}>
          <Heading color={'blackAlpha.700'} size="xs">
            {heading}
          </Heading>
          <Text fontSize={'14px'}>{description}</Text>
        </Box>
        {links.map((link) => (
          <Button
            w={'100%'}
            key={link.name}
            variant={'ghost'}
            margin={0}
            size={'xs'}
            as="a"
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => handleLinkClick(link.url, e)}
          >
            {link.name}
          </Button>
        ))}
      </Stack>
    </Box>
  )
}

export const NavBarMenu: React.FC<NavBarProps> = ({ onClose }) => {
  return (
    <Box p={4}>
      <Container maxW={'7xl'} mt="5px">
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          {CARDS_DATA.map((cardData) => (
            <Card key={cardData.heading} {...cardData} onClose={onClose} />
          ))}
        </Flex>
      </Container>
    </Box>
  )
}
