import React from 'react'

import { PriceATCResponse } from 'shared/models'
import { ListView } from 'shared/ui'
import { PriceATCItem } from './PriceATCItem'

interface PriceATCListProps {
  priceATC: PriceATCResponse[]
}

export const PriceATCListData: React.FC<PriceATCListProps> = (
  props,
): JSX.Element => {
  const { priceATC } = props

  return (
    <ListView.Lists>
      {priceATC.map((price) => (
        <PriceATCItem
          key={price.type_price_id}
          price_short_name={price.price_short_name}
          price_full_name={price.price_full_name}
          type_price_id={price.type_price_id}
          code_id={price.code_id}
        />
      ))}
    </ListView.Lists>
  )
}
