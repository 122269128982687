import { CellProps } from 'react-table'

import {
  BasicCommonColumn,
  BasicDateColumn,
  BasicTitle,
  EditableCell,
  UploadFileInfoView,
} from 'shared/ui'

import { isDataChangedSignal, tableDataSignal } from '../../signals/signals'

const size = 'lg'

export const TableStructure = (editMode) => {
  const handleChange = ({
    id,
    field,
    newValue,
  }: {
    id: string
    field: string
    newValue: string
  }) => {
    const newData = tableDataSignal.peek().map((item) => {
      if (item.month === id) {
        return {
          ...item,
          [field]: newValue,
          edited: { ...(item.edited as any), [field]: true },
          isEdited: true,
        }
      }
      return item
    })

    tableDataSignal.value = newData
    if (!isDataChangedSignal.value) isDataChangedSignal.value = true
  }

  const structure = [
    {
      title: () => <BasicTitle size={size}>Месяц</BasicTitle>,
      accessor: 'month',
      disableSortBy: true,
      minWidth: 80,
      width: 80,
      maxWidth: 80,
      className: editMode ? 'edited-cell' : '',
      CellComponent: ({ cell, row, column }: CellProps<any, string>) => {
        return (
          <BasicDateColumn
            dateFormat="date_month_string_with_year"
            align="left"
            size={size}
          >
            {row.original.month}
          </BasicDateColumn>
        )
      },
    },
    {
      title: () => <BasicTitle size={size}>330 кВ и выше, %</BasicTitle>,
      accessor: 'from_330kv_and_above',
      disableSortBy: true,
      className: editMode ? 'edited-cell' : '',
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      CellComponent: ({ row, column }: CellProps<any, string>) => {
        return editMode ? (
          <EditableCell
            double
            inputType="number"
            row={row}
            size={size}
            column={column}
            value={row.original.from_330kv_and_above}
            onUpdate={handleChange}
            id={row.original.month}
            field="from_330kv_and_above"
          />
        ) : (
          <BasicCommonColumn align="right" size={size} double>
            {row.original.from_330kv_and_above}
          </BasicCommonColumn>
        )
      },
    },
    {
      title: () => <BasicTitle size={size}>220 кВ и ниже, %</BasicTitle>,
      accessor: 'from_220kv_and_below',
      disableSortBy: true,
      className: editMode ? 'edited-cell' : '',
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      CellComponent: ({ row, column }: CellProps<any, string>) => {
        return editMode ? (
          <EditableCell
            double
            inputType="number"
            row={row}
            size={size}
            column={column}
            value={row.original.from_220kv_and_below}
            onUpdate={handleChange}
            id={row.original.month}
            field="from_220kv_and_below"
          />
        ) : (
          <BasicCommonColumn align="right" size={size} double>
            {row.original.from_220kv_and_below}
          </BasicCommonColumn>
        )
      },
    },
    {
      title: () => <BasicTitle size={size}>Реквизиты документа</BasicTitle>,
      accessor: 'document_details',
      disableSortBy: true,
      className: editMode ? 'edited-cell' : '',
      minWidth: 160,
      width: 160,
      maxWidth: 160,
      CellComponent: ({ row, column }: CellProps<any, string>) => {
        return editMode ? (
          <EditableCell
            double
            textAria
            row={row}
            size={size}
            column={column}
            value={row.original.document_details}
            onUpdate={handleChange}
            id={row.original.month}
            field="document_details"
          />
        ) : (
          <BasicCommonColumn align="center" size={size} double maxChar={25}>
            {row.original.document_details}
          </BasicCommonColumn>
        )
      },
    },
    {
      title: () => <BasicTitle size={size}>Изменено</BasicTitle>,
      accessor: 'user info',
      disableSortBy: true,
      minWidth: 120,
      width: 120,
      maxWidth: 120,
      CellComponent: ({ row }: CellProps<any, string>) => {
        const uploadInfo = {
          user: row.original.user_name,
          added_at: row.original.added_at,
          inline: true,
        }

        return (
          <BasicCommonColumn double>
            <UploadFileInfoView {...uploadInfo} largeTable allowEmpty />
          </BasicCommonColumn>
        )
      },
    },
  ]
  return structure
}
