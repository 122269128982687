import React from 'react'
import { Button, Flex } from '@chakra-ui/react'

interface SubmitCancelButtonsProps {
  isDirty: boolean
  isUpdating: boolean
  handleCancel: () => void
  isDisabled?: boolean
}

export const SubmitCancelButtons: React.FC<SubmitCancelButtonsProps> = ({
  isDirty,
  isUpdating,
  handleCancel,
  isDisabled = false,
}) => {
  if (!isDirty) return null

  return (
    <Flex justifyContent="flex-start">
      <Button
        size="xs"
        type="submit"
        isLoading={isUpdating}
        isDisabled={isUpdating || isDisabled}
        bg="teal.400"
        color="white"
        display="flex"
        alignItems="center"
        _hover={{
          bg: 'teal.500',
        }}
      >
        Сохранить
      </Button>
      <Button ml="10px" size="xs" onClick={handleCancel}>
        Отмена
      </Button>
    </Flex>
  )
}
