import { CellProps } from 'react-table'

import { BasicDateColumn, BasicSumColumn, BasicTitle } from 'shared/ui'

export const TableStructure = () => {
  const structure = [
    {
      title: () => <BasicTitle>Дата</BasicTitle>,
      accessor: 'date',
      disableSortBy: true,
      minWidth: 80,
      width: 80,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return <BasicDateColumn>{row.original.date}</BasicDateColumn>
      },
    },
    ...Array.from({ length: 24 }, (_, i) => ({
      title: () => <BasicTitle>{i}</BasicTitle>,
      accessor: `${i}`,
      disableSortBy: true,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return <BasicSumColumn>{row.original[i]}</BasicSumColumn>
      },
    })),
  ]

  return structure
}
