import { Box } from '@chakra-ui/react'
import { parse, format } from 'date-fns'
import { CellProps } from 'react-table'
import { BasicSumColumn, BasicTitle } from 'shared/ui'

export const TableStructure = (data) => {
  const allDates = new Set(
    data.flatMap((day) =>
      Object.keys(day).filter((key) => key !== 'hour' && key.includes('-')),
    ),
  )

  const convertedSet = [
    ...Array.from(allDates)
      .sort(
        (a, b) =>
          parse(a as string, 'yyyy-MM-dd', new Date()).getTime() -
          parse(b as string, 'yyyy-MM-dd', new Date()).getTime(),
      )
      .map((date) => {
        const parsedDate = parse(date as string, 'yyyy-MM-dd', new Date())
        const formattedDate = format(parsedDate, 'd')
        return {
          title: () => <BasicTitle>{formattedDate}</BasicTitle>,
          accessor: date,
          disableSortBy: true,
          CellComponent: ({ row }: CellProps<any, string>) => {
            return <BasicSumColumn>{row.original[date]}</BasicSumColumn>
          },
        }
      }),
  ]

  const structure = [
    {
      title: 'Час',
      accessor: 'hour',
      disableSortBy: true,
      minWidth: 30,
      width: 30,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return <Box fontSize={'12px'}>{row.original.hour}</Box>
      },
    },
    ...convertedSet,
  ]

  return structure
}
