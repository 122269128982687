import { useQuery } from 'react-query'

import { ActivityAreasResponse, PriceZonesResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { ReferenceBookService } from 'shared/services'

interface ActivityAreas {
  value: string
  label: string
}

interface UseGetAllActivityAreasResult {
  data: ActivityAreasResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  activityAreasData: ActivityAreas[] | undefined
}

interface UseAllActivityAreas {
  enabled: boolean
}

export function useGetAllActivityAreas({
  enabled,
}: UseAllActivityAreas): UseGetAllActivityAreasResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    ActivityAreasResponse[]
  >({
    queryKey: [queryKeys.referenceBooks.activityAreas.allActivityAreas],
    queryFn: () =>
      ReferenceBookService.getAllActivityAreas().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  const activityAreasData = data?.map((area) => ({
    value: area.gs_activity_areas_id,
    label: area.area_name,
  }))

  return { data, isError, isFetching, isLoading, activityAreasData }
}
