import { useQuery } from 'react-query'

import { GetAllACTemplatesResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PricesServiceService } from 'shared/services'

interface AllACTemplatesData {
  value: string
  label: string
}

interface UseGetAllACTemplatesServiceResult {
  data: GetAllACTemplatesResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  templatesData: AllACTemplatesData[] | undefined
}

interface UseGetAllACTemplates {
  enabled: boolean
}

export function useGetAllACTemplates({
  enabled,
}: UseGetAllACTemplates): UseGetAllACTemplatesServiceResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    GetAllACTemplatesResponse[]
  >({
    queryKey: [queryKeys.prices.allATSTemplates],
    queryFn: () =>
      PricesServiceService.getATCTemplates().then((response) => response.data),
    enabled,
    retry: false,
  })

  const templatesData = data?.map((template) => ({
    value: template.template_name,
    label: template.template_name,
  }))

  return { data, isError, isFetching, isLoading, templatesData }
}
