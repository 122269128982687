import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError, UploadExcelResponse } from 'shared/models'
import { PricesServiceService } from 'shared/services'
import { toasts } from 'shared/utils'

export interface UploadFilesSuccessAction {
  data: UploadExcelResponse
}

export interface UploadFilesFailureAction {
  errorMessage: string
  data?: UploadExcelResponse
}

interface UploadFiles {
  successAction: ({ data }: UploadFilesSuccessAction) => void
  failureAction: ({ errorMessage }: UploadFilesFailureAction) => void
  file_id: string
  file_name: string
  file: File
  force?: boolean
}

export function useUploadFiles(): {
  mutate: UseMutateFunction<
    AxiosResponse<UploadExcelResponse>,
    AxiosError,
    UploadFiles,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ file_id: string, file: File, force }: UploadFiles) =>
      PricesServiceService.uploadExcelFile({
        file_id: string,
        file: File,
        force,
      }),
    {
      onSuccess: (
        data: AxiosResponse<UploadExcelResponse>,
        variables: UploadFiles,
      ) => {
        if (data.data?.error) {
          variables.failureAction({
            errorMessage: data.data?.error,
            data: data.data,
          })
          toasts.error({
            title: 'Произошла ошибка',
            description: data.data?.error,
          })
        } else {
          queryClient.setQueryData(
            [queryKeys.prices.handleUpload.uploadFile, variables.file_name],
            data.data,
          )

          if (variables.successAction) {
            variables.successAction({ data: data.data })
            toasts.success({
              title: 'Успех',
              description: `Файл ${variables.file_name} успешно загружен`,
            })
          }
        }
      },
      onError: (error: ExtendedAxiosError, variables) => {
        const errorMessage =
          error.response?.data.detail || 'Загрузка файла не удалась'

        variables.failureAction({ errorMessage })
        toasts.error({ title: 'Произошла ошибка', description: errorMessage })
      },
    },
  )
  return { mutate, isLoading }
}
