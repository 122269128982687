import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'
import { CreateACTemplateRequest, ExtendedAxiosError } from 'shared/models'

import { PricesServiceService } from 'shared/services'
import { toasts } from 'shared/utils'

interface CreateATCTemplateMutation extends CreateACTemplateRequest {
  successAction: () => void
}

export function useCreateATCTemplate(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    CreateATCTemplateMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ template_name, data, successAction }: CreateATCTemplateMutation) =>
      PricesServiceService.createATCTemplates({
        template_name,
        data,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: CreateATCTemplateMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Шаблон был успешно добавлен`,
          })
        }
        queryClient.invalidateQueries([queryKeys.prices.allATSTemplates])
      },
      onError: (error: ExtendedAxiosError) =>
        toasts.error({
          title: 'Произошла ошибка',
          description: error.response.data.detail || 'Шаблон не был добавлен',
        }),
    },
  )
  return { mutate, isLoading }
}
