import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export interface EditATCTemplateFormData {
  template_name?: string
  items?: { template_type?: string; gs_activity_areas_id?: string }[]
}

const SCHEMA_VALIDATION = yup.object().shape({
  template_name: yup.string().required('Имя обязательное поле'),
  items: yup
    .array()
    .of(
      yup.object().shape({
        template_type: yup.string().required('Тип файла обязателен'),
        gs_activity_areas_id: yup
          .string()
          .required('Зона деятельности обязательна'),
      }),
    )
    .min(1, 'Необходимо добавить хотя бы один элемент'),
})

export const useEditATCTemplateForm = () => {
  const { register, handleSubmit, formState, control, watch, reset } =
    useForm<EditATCTemplateFormData>({
      resolver: yupResolver(SCHEMA_VALIDATION),
      defaultValues: {
        items: [{ template_type: '', gs_activity_areas_id: '' }],
      },
      mode: 'all',
    })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  })

  const watchedFields = watch()
  const items = watch('items')
  const { isValid, errors, isDirty } = formState

  return {
    register,
    handleSubmit,
    errors,
    isDirty,
    isValid,
    control,
    itemsFields: fields,
    appendItem: append,
    removeItem: remove,
    watchedFields,
    reset,
    items,
  }
}
