import React, { useMemo, useState, useEffect, memo } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import {
  Box,
  Flex,
  Text,
  Button,
  Tabs,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react'

import { DownloadReport } from 'features/prices'
import {
  DataViewSVNCEMByHour,
  DataViewSVNCEMByMont,
  Slice,
  Type,
  useGetDataViewSVNCEMByHour,
  useGetDataViewSVNCEMByMonth,
} from 'entities/prices'
import { useGetAllActivityAreas } from 'entities/referenceBooks'
import { DatePickerField, FormInputBlock, SelectInputForm } from 'shared/ui'
import { convertDateForAPI } from 'shared/utils'

import { Period } from '../types/types'
import { useDataViewSVNCEMForm } from './DataViewSVNCEMForm'

// misc
import { HiOutlineTableCells } from 'react-icons/hi2'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const DataViewSVNCEM: React.FC = memo(() => {
  const query = useQuery()

  const queryPeriod = query.get('period')
  const gsActivityAreasId = query.get('gs_activity_areas_id')

  const [slice, setSlice] = useState<Slice>(Slice.dateHour)
  const [type, setType] = useState<Type>(Type.br)
  const [period, setPeriod] = useState<Period>(Period.byMonth)
  const [fetchEnabled, setFetchEnabled] = useState<{
    enabled: boolean
    period: Period
  }>({
    enabled: false,
    period: null,
  })

  const { activityAreasData } = useGetAllActivityAreas({
    enabled: true,
  })

  const {
    register,
    handleSubmit,
    errors,
    reset,
    isDirty,
    watchedFields,
    control,
    setValue,
    setError,
    watch,
    isValid,
  } = useDataViewSVNCEMForm()

  useEffect(() => {
    if (queryPeriod && gsActivityAreasId) {
      reset({
        date_period: moment(queryPeriod).format('DD.MM.yyyy'),
        gs_activity_areas_id: gsActivityAreasId,
      })
    }
  }, [queryPeriod, gsActivityAreasId])

  const resetFetchState = () => {
    setFetchEnabled({
      enabled: false,
      period: null,
    })
  }

  const convertedDatePeriod: string = convertDateForAPI(
    watchedFields.date_period,
  )

  const {
    data: dataViewSVNCEMByHour,
    upload_info: uploadInfoDataViewSVNCEMByHour,
    isLoading: isLoadingDataViewSVNCEMByHour,
    isFetching: isFetchingDataViewSVNCEMByHour,
    refetch: refetchDataViewSVNCEMByHour,
  } = useGetDataViewSVNCEMByHour({
    enabled: fetchEnabled.enabled && fetchEnabled.period === Period.byHour,
    gs_activity_areas_id: watchedFields.gs_activity_areas_id,
    date_period: convertedDatePeriod,
    successAction: () => resetFetchState(),
    errorAction: () => resetFetchState(),
  })

  const {
    data: dataViewSVNCEMByMonth,
    upload_info: uploadInfoDataViewSVNCEMMonth,
    isLoading: isLoadingDataViewSVNCEMMonth,
    isFetching: isFetchingDataViewSVNCEMMonth,
    refetch: refetchDataViewSVNCEMMonth,
  } = useGetDataViewSVNCEMByMonth({
    enabled: fetchEnabled.enabled && fetchEnabled.period === Period.byMonth,
    gs_activity_areas_id: watchedFields.gs_activity_areas_id,
    date_period: convertedDatePeriod,
    successAction: () => resetFetchState(),
    errorAction: () => resetFetchState(),
  })

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  const handleFetchData = () => {
    if (period === Period.byHour) refetchDataViewSVNCEMByHour()
    if (period === Period.byMonth) refetchDataViewSVNCEMMonth()
  }

  const dataViewSVNCEMByHourProps = {
    type,
    setSlice,
    setType,
    slice,
    data: dataViewSVNCEMByHour,
    upload_info: uploadInfoDataViewSVNCEMByHour,
  }

  const dataViewSVNCEMByMonthProps = {
    data: dataViewSVNCEMByMonth,
    upload_info: uploadInfoDataViewSVNCEMMonth,
  }

  const downloadReportByMonthProps = {
    data: dataViewSVNCEMByMonth,
    isDisabled: !dataViewSVNCEMByMonth || !dataViewSVNCEMByMonth?.length,
    gs_activity_areas_id: watchedFields.gs_activity_areas_id,
    date_period: convertDateForAPI(watchedFields.date_period),
    report_type: period,
  }

  const downloadReportByHourProps = {
    isDisabled: !dataViewSVNCEMByHour || !dataViewSVNCEMByHour?.length,
    gs_activity_areas_id: watchedFields.gs_activity_areas_id,
    date_period: convertDateForAPI(watchedFields.date_period),
    report_type: period,
    slice,
  }

  return (
    <Box p="2">
      <form>
        <Box ml="10px">
          <Flex alignItems={'center'}>
            <Box mr="25px">
              <FormInputBlock
                titleWidth={'60px'}
                allowEdit={true}
                title={'Период'}
                edit={true}
              >
                <DatePickerField
                  isReadOnly
                  format="MM.yyyy"
                  showMonthYearPicker
                  name={'date_period'}
                  placeholder={'Период'}
                  type={'text'}
                  theme="monthPicker"
                  {...commonInputProps}
                />
              </FormInputBlock>
            </Box>
            <Box w={'480px'}>
              <FormInputBlock
                titleWidth={'180px'}
                allowEdit={true}
                edit
                title={'Зона деятельности ГП'}
              >
                <SelectInputForm
                  placeholder="Выберите зона деятельности ГП"
                  getOptionValue={(option) => (option ? option.value : '')}
                  name="gs_activity_areas_id"
                  data={activityAreasData || []}
                  isClearable={true}
                  {...commonInputProps}
                />
              </FormInputBlock>
            </Box>
          </Flex>
          <Box mt="20px">
            <Flex>
              <Button
                w={'135px'}
                size="xs"
                borderRadius={0}
                borderLeftRadius={'5px'}
                border={'1px solid #ececec'}
                borderRight={'none'}
                _hover={{ bg: '#3285d2', color: 'white' }}
                bg={period === Period.byMonth ? '#2d70af' : '#f8f8f8'}
                color={period === Period.byMonth ? 'white' : 'black'}
                onClick={() => setPeriod(Period.byMonth)}
              >
                Месячные
              </Button>
              <Button
                w={'135px'}
                size="xs"
                borderRadius={0}
                borderRightRadius={'5px'}
                border={'1px solid #ececec'}
                _hover={{ bg: '#3285d2', color: 'white' }}
                bg={period === Period.byHour ? '#2d70af' : '#f8f8f8'}
                color={period === Period.byHour ? 'white' : 'black'}
                onClick={() => setPeriod(Period.byHour)}
              >
                Почасовые
              </Button>
              <Box ml="10px">
                <Button
                  w={'135px'}
                  position={'relative'}
                  size={'xs'}
                  color="white"
                  display={'flex'}
                  alignItems={'center'}
                  onClick={handleFetchData}
                  colorScheme="teal"
                  isDisabled={!isValid}
                  isLoading={
                    isFetchingDataViewSVNCEMByHour ||
                    isFetchingDataViewSVNCEMMonth ||
                    isLoadingDataViewSVNCEMMonth ||
                    isLoadingDataViewSVNCEMByHour
                  }
                >
                  <Box fontSize={'18px'} mr={'5px'}>
                    <HiOutlineTableCells />
                  </Box>
                  <Text position={'relative'}>Показать</Text>
                </Button>
              </Box>
            </Flex>
          </Box>
        </Box>

        {(dataViewSVNCEMByMonth || dataViewSVNCEMByHour) && (
          <Box
            mt="15px"
            p="2"
            border={'1px solid #dfe0eb'}
            borderRadius={'5px'}
          >
            <Tabs index={period === Period.byMonth ? 0 : 1} isManual>
              <TabPanels>
                <TabPanel padding={0}>
                  <DataViewSVNCEMByMont
                    {...dataViewSVNCEMByMonthProps}
                    downloadReportSlot={
                      <DownloadReport {...downloadReportByMonthProps} />
                    }
                  />
                </TabPanel>
                <TabPanel padding={0}>
                  <DataViewSVNCEMByHour
                    {...dataViewSVNCEMByHourProps}
                    downloadReportSlot={
                      <Box ml={'15px'}>
                        <DownloadReport {...downloadReportByHourProps} />
                      </Box>
                    }
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        )}
      </form>
    </Box>
  )
})
