import { CellProps } from 'react-table'

import {
  BasicCommonColumn,
  BasicDateColumn,
  BasicTitle,
  EditableCell,
  UploadFileInfoView,
} from 'shared/ui'
import { isDataChangedSignal, tableDataSignal } from '../../signals/signals'

const size = 'lg'

export const TableStructure = (editMode) => {
  const handleChange = ({
    id,
    field,
    newValue,
  }: {
    id: string
    field: string
    newValue: string
  }) => {
    const newData = tableDataSignal.peek().map((item) => {
      if (item.month === id) {
        return {
          ...item,
          [field]: newValue,
          edited: { ...item.edited, [field]: true },
          isEdited: true,
        }
      }
      return item
    })

    tableDataSignal.value = newData
    if (!isDataChangedSignal.value) isDataChangedSignal.value = true
  }

  const structure = [
    {
      title: () => <BasicTitle size={size}>Месяц</BasicTitle>,
      accessor: 'month	',
      disableSortBy: true,
      minWidth: 80,
      width: 80,
      className: editMode ? 'edited-cell' : '',

      CellComponent: ({ cell, row, column }: CellProps<any, string>) => {
        return (
          <BasicDateColumn
            dateFormat="date_month_string_with_year"
            align="left"
            size={size}
          >
            {row.original.month}
          </BasicDateColumn>
        )
      },
    },
    {
      title: () => <BasicTitle size={size}>Потери</BasicTitle>,
      accessor: 'losses',
      disableSortBy: true,
      minWidth: 80,
      width: 80,
      className: editMode ? 'edited-cell' : '',
      CellComponent: ({ row, column }: CellProps<any, string>) => {
        return editMode ? (
          <EditableCell
            double
            inputType="number"
            row={row}
            column={column}
            value={row.original.losses}
            onUpdate={handleChange}
            id={row.original.month}
            field="losses"
            size={size}
          />
        ) : (
          <BasicCommonColumn align="right" size={size} double>
            {row.original.losses}
          </BasicCommonColumn>
        )
      },
    },
    {
      title: () => <BasicTitle size={size}>Менее 670 кВт</BasicTitle>,
      accessor: 'less_than_670kw',
      disableSortBy: true,
      minWidth: 80,
      width: 80,
      className: editMode ? 'edited-cell' : '',
      CellComponent: ({ row, column }: CellProps<any, string>) => {
        return editMode ? (
          <EditableCell
            double
            inputType="number"
            row={row}
            column={column}
            value={row.original.less_than_670kw}
            onUpdate={handleChange}
            id={row.original.month}
            field="less_than_670kw"
            size={size}
          />
        ) : (
          <BasicCommonColumn align="right" size={size} double>
            {row.original.less_than_670kw}
          </BasicCommonColumn>
        )
      },
    },
    {
      title: () => <BasicTitle size={size}>От 670 кВт до 10 МВт</BasicTitle>,
      accessor: 'from_670kw_to_10mw',
      className: editMode ? 'edited-cell' : '',
      disableSortBy: true,
      minWidth: 80,
      width: 80,
      CellComponent: ({ row, column }: CellProps<any, string>) => {
        return editMode ? (
          <EditableCell
            double
            inputType="number"
            row={row}
            column={column}
            value={row.original.from_670kw_to_10mw}
            onUpdate={handleChange}
            id={row.original.month}
            field="from_670kw_to_10mw"
            size={size}
          />
        ) : (
          <BasicCommonColumn align="right" size={size} double>
            {row.original.from_670kw_to_10mw}
          </BasicCommonColumn>
        )
      },
    },
    {
      title: () => <BasicTitle size={size}>Свыше 10 МВт</BasicTitle>,
      accessor: 'greater_than_10mw',
      disableSortBy: true,
      className: editMode ? 'edited-cell' : '',
      minWidth: 80,
      width: 80,
      CellComponent: ({ row, column }: CellProps<any, string>) => {
        return editMode ? (
          <EditableCell
            double
            inputType="number"
            row={row}
            column={column}
            value={row.original.greater_than_10mw}
            onUpdate={handleChange}
            id={row.original.month}
            field="greater_than_10mw"
            size={size}
          />
        ) : (
          <BasicCommonColumn align="right" size={size} double>
            {row.original.greater_than_10mw}
          </BasicCommonColumn>
        )
      },
    },
    {
      title: () => <BasicTitle size={size}>Реквизиты документа</BasicTitle>,
      accessor: 'document_details',
      disableSortBy: true,
      className: editMode ? 'edited-cell' : '',
      minWidth: 120,
      width: 120,
      CellComponent: ({ row, column }: CellProps<any, string>) => {
        return editMode ? (
          <EditableCell
            double
            textAria
            row={row}
            column={column}
            value={row.original.document_details}
            onUpdate={handleChange}
            id={row.original.month}
            field="document_details"
            size={size}
          />
        ) : (
          <BasicCommonColumn align="center" size={size} double maxChar={60}>
            {row.original.document_details}
          </BasicCommonColumn>
        )
      },
    },
    {
      title: () => <BasicTitle size={size}>Изменено</BasicTitle>,
      accessor: 'user info	',
      disableSortBy: true,
      minWidth: 120,
      width: 120,
      maxWidth: 120,
      CellComponent: ({ row }: CellProps<any, string>) => {
        const uploadInfo = {
          user: row.original.user_name,
          added_at: row.original.added_at,
          inline: true,
          largeTable: true,
        }

        return (
          <BasicCommonColumn>
            <UploadFileInfoView {...uploadInfo} largeTable allowEmpty />
          </BasicCommonColumn>
        )
      },
    },
  ]
  return structure
}
