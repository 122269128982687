import { Box, Flex } from '@chakra-ui/react'

// misc
import { HiOutlineCircleStack } from 'react-icons/hi2'

export const EmptyDataMessage = () => {
  return (
    <Flex mt={'5px'} alignItems={'center'} opacity={'0.7'}>
      <Box mr={'5px'} position={'relative'} top={'2px'}>
        <HiOutlineCircleStack />
      </Box>
      Данные отсутствуют в базе
    </Flex>
  )
}
