/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react'
import { parse } from 'date-fns'

import { BasicTable } from 'shared/ui'
import { months } from 'shared/dictionary'
import { TableStructure } from './TableStructure'

interface HourData {
  [date: string]: string
}

interface TableDataItem extends HourData {
  hour: string
}

interface ITableEntityProps {
  tableData: TableDataItem[]
}

export const TableEntity: React.FC<ITableEntityProps> = (props) => {
  const { tableData = [] } = props

  const columns = TableStructure(tableData).map((column) => ({
    ...column,
    accessor: column.accessor as string,
  }))

  const data = useMemo(() => tableData, [tableData])

  const firstDate =
    tableData.length > 0
      ? Object.keys(tableData[0]).find((key) => key.includes('-'))
      : null
  const parsedDate = firstDate
    ? parse(firstDate, 'yyyy-MM-dd', new Date())
    : null
  const monthIndex = parsedDate ? parsedDate.getMonth() : null
  const year = parsedDate ? parsedDate.getFullYear() : null
  const month = monthIndex !== null ? months[monthIndex] : 'Unknown Month'
  const monthYearDisplay =
    monthIndex !== null && year ? `${month} ${year}` : 'Unknown Date'

  const CustomHeader = ({ monthYear }) => (
    <tr>
      <th {...{ colSpan: 32, style: { textAlign: 'center' } }}>{monthYear}</th>
    </tr>
  )

  const tableProps = useMemo(
    () => ({
      tableStyles: {
        tableMaxHeight: '65vh',
        tableWidth: '100%',
      },
      data,
      columns,
      emptyText: 'Данные отсутствуют',
      loading: false,
      showTableMenu: false,
      smallShadow: true,
      lightHeader: true,
      smallHeader: true,
      customHeader: () => <CustomHeader monthYear={monthYearDisplay} />,
    }),
    [data, columns, monthYearDisplay],
  )

  return <BasicTable {...tableProps} />
}
