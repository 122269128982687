import { UploadFileInfo } from 'shared/models'
import { Flex, Text } from '@chakra-ui/react'
import { getTimeDifferenceToMoscow, normalizeValues } from 'shared/utils'

interface UploadFileInfoViewProps extends UploadFileInfo {
  inline?: boolean
  smallTable?: boolean
  largeTable?: boolean
  allowEmpty?: boolean
}

export const UploadFileInfoView: React.FC<UploadFileInfoViewProps> = ({
  user,
  added_at,
  inline = false,
  smallTable = false,
  largeTable = false,
  allowEmpty = false,
}): JSX.Element => {
  if (allowEmpty && !added_at) return <></>

  return (
    <Flex alignItems={'center'} flexDir={inline ? 'row' : 'column'}>
      {!smallTable && !largeTable && (
        <Flex alignItems={'center'}>
          <Text fontSize={'12px'} color={'#63a084'}>
            Последние изменения:
          </Text>
        </Flex>
      )}
      <Flex flexDir={largeTable || smallTable ? 'column' : 'row'}>
        <Text fontSize={smallTable ? '9px' : '12px'} ml={'5px'}>
          {user}
        </Text>
        <Text
          fontSize={smallTable ? '9px' : '12px'}
          fontWeight={'bold'}
          ml={'5px'}
          color={'blue.700'}
        >
          {normalizeValues(added_at, 'utc_to_local_with_time')}
          <Text
            as={'span'}
            ml={'5px'}
            fontSize={smallTable ? '9px' : '12px'}
            color={'gray.500'}
            fontWeight={'normal'}
          >
            {smallTable && <br />}
            (МСК +{getTimeDifferenceToMoscow()})
          </Text>
        </Text>
      </Flex>
    </Flex>
  )
}
