/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { BasicTable } from 'shared/ui'

import { TableStructure } from './TableStructure'
import { FlattenedTransServicesData } from '../../types/types'

interface ITableEntityProps {
  tableData: FlattenedTransServicesData[]
  editMode: boolean
}

export const TableEntity: React.FC<ITableEntityProps> = (
  props,
): JSX.Element => {
  const { tableData = [], editMode } = props

  const columns = TableStructure(editMode)

  const styles = {
    textAlign: 'center',
    fontSize: '14px',
  }

  const CustomHeader = () => (
    <tr>
      <th
        {...({
          colSpan: 1,
          style: { ...styles },
        } as any)}
      >
        Месяц
      </th>
      <th
        {...({
          colSpan: 4,
          style: { ...styles },
        } as any)}
      >
        Двухставочный (тех. потери), руб./МВт*ч
      </th>
      <th
        {...({
          colSpan: 4,
          style: { ...styles },
        } as any)}
      >
        Двухставочный (содержание сетей), руб./МВт
      </th>
      <th
        {...({
          colSpan: 1,
          style: { ...styles },
        } as any)}
      >
        Реквизиты документа
      </th>
      <th
        {...({
          colSpan: 1,
          style: { ...styles },
        } as any)}
      >
        Изменено
      </th>
    </tr>
  )

  const tableProps = {
    tableStyles: {
      tableMaxHeight: '70vh',
      tableWidth: '100%',
    },
    data: tableData,
    columns,
    emptyText: 'Данные отсутствуют',
    loading: false,
    showTableMenu: false,
    smallShadow: true,
    lightHeader: true,
    smallHeader: false,
    customHeader: () => <CustomHeader />,
  }

  return (
    <>
      <BasicTable {...tableProps} />
    </>
  )
}
