import { useCallback } from 'react'
import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react'

import { Access } from 'app/providers'
import { ROLES } from 'shared/constants'
import { AddUser } from 'features/users'

import './Users.scss'

// misc
import { HiMiniPlusCircle } from 'react-icons/hi2'

export const UserSettingsHeader: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleAddUser = useCallback(() => {
    onOpen()
  }, [onOpen])

  const addUserProps = {
    isOpen,
    onOpen,
    onClose,
  }

  return (
    <>
      <Access roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
        <AddUser {...addUserProps} />
      </Access>
      <Flex justify={'flex-start'} alignItems={'center'} h={'100%'}>
        <Access roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <Button
            mr="10px"
            mt="10px"
            position={'relative'}
            size={'xs'}
            bg="blue.700"
            color="white"
            display={'flex'}
            alignItems={'center'}
            onClick={handleAddUser}
            _hover={{
              bg: 'blue.600',
            }}
          >
            <Box fontSize={'18px'} mr={'5px'}>
              <HiMiniPlusCircle />
            </Box>
            <Text position={'relative'}>Добавить пользователя</Text>
          </Button>
        </Access>
      </Flex>
    </>
  )
}
