import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { ImportData, ImportDataATC } from 'widgets'

export const ImportDataPage: React.FC = (): React.ReactElement => {
  const [tabIndex, setTabIndex] = useState<number>(0)

  const handleTabsChange = (index) => {
    setTabIndex(index)
  }

  return (
    <Tabs index={tabIndex} onChange={handleTabsChange}>
      <TabList p={'10px'} pb={0}>
        <Tab>
          <Text fontSize={'18px'}>Импорт файлов</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Загрузка с АТС</Text>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding={0}>
          <ImportData />
        </TabPanel>
        <TabPanel padding={0}>
          <ImportDataATC isActive={tabIndex === 1} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
