import {
  Control,
  Controller,
  FieldErrors,
  Path,
  UseFormRegister,
} from 'react-hook-form'

import { SelectInput } from './SelectInput'
import { Box, Flex, Text } from '@chakra-ui/react'
import { IoIosWarning } from 'react-icons/io'
import { AiOutlineCheck } from 'react-icons/ai'
import { Tooltip } from '../Tooltip/Tooltip'

interface SelectOption {
  label: string
  value: any
}

interface SelectInputFormProps<T> {
  control: Control<T>
  name: Path<T>
  register: UseFormRegister<T>
  errors: FieldErrors<T>
  watchedFields: T
  data: SelectOption[]
  placeholder?: string
  isRequired?: boolean
  isCreatable?: boolean
  isClearable?: boolean
  isReadOnly?: boolean
  isMulti?: boolean
  isDisabled?: boolean
  notificationText?: string
  size?: 'sm' | 'md'
  getOptionValue?: (option: any) => any
  smallErrorTextInside?: boolean
}

const getNestedValue = (object, path) =>
  path.split('.').reduce((o, p) => o?.[p], object)

export const SelectInputForm = <T,>({
  errors,
  control,
  watchedFields,
  name,
  data,
  placeholder = '',
  isRequired = false,
  isReadOnly = false,
  isCreatable = false,
  isClearable = false,
  notificationText = '',
  smallErrorTextInside = false,
  isDisabled = false,
  size = 'md',
  getOptionValue,
}: SelectInputFormProps<T>) => {
  const fieldValue = getNestedValue(watchedFields, name)
  const fieldError = getNestedValue(errors, name)
  const isTouched = fieldValue !== undefined && fieldValue !== ''
  const isValid = isTouched && !fieldError

  return (
    <Box
      position={'relative'}
      w={'100%'}
      maxW={'100%'}
      opacity={isDisabled ? 0.3 : 1}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <SelectInput
              isReadOnly={isReadOnly}
              error={errors && !!errors[name as string]}
              success={isValid && isRequired}
              size={size}
              isCreatable={isCreatable}
              data={data}
              isClearable={isClearable}
              placeholder={placeholder}
              notificationText={notificationText}
              value={
                data?.find(
                  (option) => getOptionValue(option) === field.value,
                ) ||
                (field.value
                  ? { label: field.value, value: field.value }
                  : null)
              }
              onChange={(option) => {
                const newValue = option ? getOptionValue(option) : ''
                field.onChange(newValue)
              }}
            />
          )
        }}
      />
      {isRequired && !isTouched && (
        <Box
          position="absolute"
          right="33px"
          top={size === 'sm' ? '6px' : '9px'}
          color="gray.400"
          fontSize={size === 'sm' ? '20px' : '22px'}
        >
          <Tooltip
            id={`${name}-${placeholder}-warning`}
            content="Данное поле является обязательным для заполнения"
            place="top"
          >
            <Box
              w={size === 'sm' ? '14px' : '18px'}
              opacity={''}
              color={fieldError ? 'red.500' : '#e5e6e5'}
            >
              <IoIosWarning />
            </Box>
          </Tooltip>
        </Box>
      )}
      {fieldError && smallErrorTextInside && (
        <Flex
          alignItems="center"
          color="red.600"
          position={'absolute'}
          top={'-6px'}
          left={'5px'}
          background={'#fff'}
          borderRadius={'20px'}
          paddingRight={'5px'}
          zIndex={'9'}
        >
          <Text
            fontSize="10px"
            fontWeight={'700'}
            ml="5px"
            lineHeight={'11px'}
            whiteSpace={'nowrap'}
          >
            {fieldError.message}
          </Text>
        </Flex>
      )}
      {isValid && isRequired && (
        <Flex
          alignItems="center"
          color="green.600"
          position={'absolute'}
          right={'28px'}
          bottom={size === 'sm' ? '9px' : '12px'}
        >
          <Tooltip
            id={`${name}-${placeholder}-success`}
            content="Данное поле успешно прошло валидацию"
            place="top"
          >
            <AiOutlineCheck />
          </Tooltip>
        </Flex>
      )}
    </Box>
  )
}
