import { AxiosPromise } from 'axios'

import {
  ActivityAreasResponse,
  AddHolydayRequest,
  AddNewActivityAreaRequest,
  AddNewEnergySystemRequest,
  AddNewPriceZoneRequest,
  AddNewSubjectRequest,
  CreateGroupDeliveryRequest,
  DeleteEnergySystemRequest,
  DeleteGroupDeliveryRequest,
  DeleteHolydayRequest,
  DeletePriceZoneRequest,
  EnergySystemResponse,
  GroupDeliveryResponse,
  HolydaysResponse,
  JuridicalPersonResponse,
  PriceZonesResponse,
  SubjectsResponse,
  UpdateActivityAreaRequest,
  UpdateEnergySystemRequest,
  UpdateGroupDeliveryRequest,
  UpdatePriceZoneRequest,
  UpdateSubjectRequest,
} from 'shared/models'

import { ServiceBase } from './serviceBase'

export class ReferenceBookService extends ServiceBase {
  protected static BASE_PATH = 'reference_books'

  // NOTE: holydays

  public static getAllHolydays(): AxiosPromise<HolydaysResponse[]> {
    return this.get<HolydaysResponse[]>(
      `${this.BASE_PATH}/calendar/list_calendar_weekends`,
    )
  }

  public static addHolyday({
    weekend_date,
  }: AddHolydayRequest): AxiosPromise<undefined> {
    return this.post<undefined>(
      `${this.BASE_PATH}/calendar/create_weekend_date`,
      { weekend_date },
    )
  }

  public static deleteHolyday({
    weekend_id,
  }: DeleteHolydayRequest): AxiosPromise<undefined> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/calendar/delete_weekend_date/${weekend_id}`,
    )
  }

  public static clearHolyday({
    year,
  }: {
    year: string
  }): AxiosPromise<undefined> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/calendar/delete_weekend_dates_by_year/${year}`,
    )
  }

  // NOTE: subjects

  public static getAllSubjects(): AxiosPromise<SubjectsResponse[]> {
    return this.get<SubjectsResponse[]>(
      `${this.BASE_PATH}/subjects/list_subjects`,
    )
  }

  public static updateSubject({
    subject_id,
    region_code,
    region_name,
  }: UpdateSubjectRequest): AxiosPromise<undefined> {
    return this.patch<undefined>(
      `${this.BASE_PATH}/subjects/update_subject/${subject_id}`,
      {
        region_code,
        region_name,
      },
    )
  }

  public static addNewSubject({
    region_code,
    region_name,
  }: AddNewSubjectRequest): AxiosPromise<undefined> {
    return this.post<undefined>(`${this.BASE_PATH}/subjects/create_subject`, {
      region_code,
      region_name,
    })
  }

  public static deleteSubject({
    subject_id,
  }: {
    subject_id: string
  }): AxiosPromise<undefined> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/subjects/delete_subject/${subject_id}`,
    )
  }

  // NOTE: energy system

  public static getAllEnergySystems(): AxiosPromise<EnergySystemResponse[]> {
    return this.get<EnergySystemResponse[]>(
      `${this.BASE_PATH}/united_energy_system/list_ue_systems`,
    )
  }

  public static updateEnergySystem({
    system_name,
    ue_system_id,
  }: UpdateEnergySystemRequest): AxiosPromise<undefined> {
    return this.patch<undefined>(
      `${this.BASE_PATH}/united_energy_system/update_ue_system/${ue_system_id}`,
      {
        system_name,
      },
    )
  }

  public static deleteEnergySystem({
    ue_system_id,
  }: DeleteEnergySystemRequest): AxiosPromise<undefined> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/united_energy_system/delete_ue_system/${ue_system_id}`,
    )
  }

  public static addNewEnergySystem({
    system_name,
  }: AddNewEnergySystemRequest): AxiosPromise<undefined> {
    return this.post<undefined>(
      `${this.BASE_PATH}/united_energy_system/create_ue_system`,
      {
        system_name,
      },
    )
  }

  // NOTE: price zones

  public static addNewPriceZone({
    zone_name,
  }: AddNewPriceZoneRequest): AxiosPromise<undefined> {
    return this.post<undefined>(
      `${this.BASE_PATH}/price_zone/create_price_zone`,
      {
        zone_name,
      },
    )
  }

  public static getAllPriceZones(): AxiosPromise<PriceZonesResponse[]> {
    return this.get<PriceZonesResponse[]>(
      `${this.BASE_PATH}/price_zone/list_price_zones`,
    )
  }

  public static updatePriceZone({
    zone_name,
    price_zone_id,
  }: UpdatePriceZoneRequest): AxiosPromise<undefined> {
    return this.patch<undefined>(
      `${this.BASE_PATH}/price_zone/update_price_zone/${price_zone_id}`,
      {
        zone_name,
      },
    )
  }

  public static deletePriceZone({
    price_zone_id,
  }: DeletePriceZoneRequest): AxiosPromise<undefined> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/price_zone/delete_price_zone/${price_zone_id}`,
    )
  }

  // NOTE: activity_areas

  public static getAllActivityAreas(): AxiosPromise<ActivityAreasResponse[]> {
    return this.get<ActivityAreasResponse[]>(
      `${this.BASE_PATH}/guaranteeing_supplier_activity_areas/list_gs_activity_areas`,
    )
  }

  public static getDetailActivityAreas({
    gs_activity_areas_id,
  }: {
    gs_activity_areas_id: string
  }): AxiosPromise<ActivityAreasResponse> {
    return this.get<ActivityAreasResponse>(
      `${this.BASE_PATH}/guaranteeing_supplier_activity_areas/detail_gs_activity_areas/${gs_activity_areas_id}`,
    )
  }

  public static updateActivityArea({
    gs_activity_areas_id,
    area_name,
    price_zone_id,
    ue_system_id,
    subject_id,
    time_zone,
  }: UpdateActivityAreaRequest): AxiosPromise<undefined> {
    return this.patch<undefined>(
      `${this.BASE_PATH}/guaranteeing_supplier_activity_areas/${gs_activity_areas_id}`,
      {
        area_name,
        price_zone_id,
        ue_system_id,
        subject_id,
        time_zone,
      },
    )
  }

  public static addNewActivityArea({
    area_name,
    price_zone_id,
    ue_system_id,
    subject_id,
    time_zone,
    related_group_delivery_points,
  }: AddNewActivityAreaRequest): AxiosPromise<undefined> {
    return this.post<undefined>(
      `${this.BASE_PATH}/guaranteeing_supplier_activity_areas/create_gs_activity_area`,
      {
        area_name,
        price_zone_id,
        ue_system_id,
        subject_id,
        time_zone,
        ...(related_group_delivery_points && {
          related_group_delivery_points,
        }),
      },
    )
  }

  public static deleteActivityArea({
    gs_activity_areas_id,
  }: {
    gs_activity_areas_id: string
  }): AxiosPromise<undefined> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/guaranteeing_supplier_activity_areas/delete_gsa_area/${gs_activity_areas_id}`,
    )
  }

  public static updateGroupDelivery({
    juridical_person,
    gs_activity_areas_id,
    date_from,
    date_to,
    new_date_to,
    new_date_from,
    gd_point_names,
  }: UpdateGroupDeliveryRequest): AxiosPromise<undefined> {
    return this.patch<undefined>(
      `${this.BASE_PATH}/related_group_delivery_points/${gs_activity_areas_id}`,
      {
        date_from,
        gd_point_names,
        new_date_from,
        ...(new_date_to && { new_date_to }),
        ...(date_to && { date_to }),
        ...(juridical_person && { juridical_person }),
      },
    )
  }

  public static createGroupDelivery({
    gs_activity_areas_id,
    date_from,
    date_to,
    gd_point_names,
    juridical_person,
  }: CreateGroupDeliveryRequest): AxiosPromise<undefined> {
    return this.post<undefined>(
      `${this.BASE_PATH}/related_group_delivery_points?gs_activity_areas_id=${gs_activity_areas_id}`,
      {
        date_from,
        gd_point_names,
        ...(juridical_person && { juridical_person }),
        ...(date_to !== null && { date_to }),
      },
    )
  }

  public static getAllGroupDelivery(): AxiosPromise<GroupDeliveryResponse[]> {
    return this.get<GroupDeliveryResponse[]>(
      `${this.BASE_PATH}/group_delivery_points/list_gd_points`,
    )
  }

  public static getAllJuridicalPersons(): AxiosPromise<
    JuridicalPersonResponse[]
  > {
    return this.get<JuridicalPersonResponse[]>(
      `${this.BASE_PATH}/list_juridical_persons`,
    )
  }

  public static deleteGroupDelivery({
    gs_activity_areas_id,
    date_from,
    date_to,
  }: DeleteGroupDeliveryRequest): AxiosPromise<undefined> {
    const url =
      `${this.BASE_PATH}/related_group_delivery_points/delete_rgd_points/${gs_activity_areas_id}` +
      (date_from
        ? `?date_from=${date_from}${date_to ? `&date_to=${date_to}` : ''}`
        : '')
    return this.delete<undefined>(url)
  }
}
