import React, { useState, useRef, useEffect } from 'react'

import moment from 'moment'
// import Calendar from 'react-datepicker'
import DatePicker from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import { addMonths, format, subMonths } from 'date-fns'
import { getYear, getMonth, Locale } from 'date-fns'
import { Portal } from 'react-overlays'
import MaskedTextInput from 'react-text-mask'
import clsx from 'clsx'

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  SimpleGrid,
  Box,
  Flex,
  Text,
  Input,
  IconButton,
} from '@chakra-ui/react'

import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form'

import { Tooltip } from 'shared/ui'

// misc
import { AiOutlineCheck } from 'react-icons/ai'
import { IoIosWarning } from 'react-icons/io'
import { CloseIcon } from '@chakra-ui/icons'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi2'
import { FaChevronLeft } from 'react-icons/fa6'
import { FaChevronRight } from 'react-icons/fa6'
import { months } from 'shared/dictionary'

type MaskArray = (RegExp | string)[]
interface YearGridProps {
  selectedYear: number
  onSelect: (year: number) => void
  minDate?: Date
  maxDate?: Date
}

interface MonthGridProps {
  selectedMonth: number
  onSelect: (monthIndex: number) => void
  minDate: Date
  maxDate: Date
  selectedYear: number
}

const classes = {
  holydays: 'holydaysWrapper',
  dates: 'datesWrapper',
  monthPicker: 'monthPicker',
  yearPicker: 'yearPicker',
}

interface DatePickerInputProps<T> {
  control: Control<T>
  register: UseFormRegister<T>
  errors: FieldErrors<T>
  watchedFields: T
  name: Path<T>
  placeholder: string
  type: string
  isRequired: boolean
  onChangeFormatter?: (inputValue: string) => string
  showMonthYearPicker?: boolean
  showFullMonthYearPicker?: boolean
  showYearPicker?: boolean
  showOnlyYearPicker?: boolean
  showTimeSelect?: boolean
  inline?: boolean
  isClearable?: boolean
  format?: string
  theme?: 'holydays' | 'dates' | 'monthPicker' | 'yearPicker'
  minDate?: Date
  maxDate?: Date
  isReadOnly?: boolean
  size?: 'sm' | 'md' | 'xs'
  mask?: string | MaskArray
  smallErrorTextInside?: boolean
  years?: number[]
  months?: string[]
}

interface ICalendarContainer {
  children: React.ReactNode
  theme?: 'holydays' | 'dates' | 'monthPicker' | 'yearPicker'
}

const CalendarContainer: React.FC<ICalendarContainer> = ({
  children,
  theme,
}) => {
  const className = classes[theme] || classes.holydays
  const el = document.getElementById('calendar-portal')

  return (
    <Portal container={el}>
      <div className={clsx(className)}>{children}</div>
    </Portal>
  )
}

const getStyles = {
  md: {
    fontSize: '22px',
    errorFontSize: '12px',
    successIconTop: '3px',
    successBoxShadow: '0 0 0 1px #04bcb0b6',
    errorBoxShadow: '0 0 0 1px #04bcb0b6',
    errorIconTop: '9px',
    errorRight: '10px',
  },
  sm: {
    fontSize: '20px',
    errorFontSize: '10px',
    successIconTop: '7px',
    successBoxShadow: '0 0 0 1px #04bcb0b6',
    errorBoxShadow: '0 0 0 1px #04bcb0b6',
    errorIconTop: '6px',
    errorRight: '10px',
  },
  xs: {
    fontSize: '18px',
    errorFontSize: '10px',
    successIconTop: '5px',
    successBoxShadow: 'none',
    errorBoxShadow: 'none',
    errorIconTop: '3px',
    errorRight: '7px',
  },
}

const getNestedValue = (object, path) =>
  path.split('.').reduce((o, p) => o?.[p], object)

const generateYearRange = (startYear: number, endYear: number): number[] => {
  const years: number[] = []
  for (let year = startYear; year <= endYear; year++) {
    years.push(year)
  }
  return years
}
const yearsRange: number[] = generateYearRange(2005, 2050)

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

const getMonthsArray = (locale: Locale): string[] => {
  let months: string[] = []
  for (let month = 0; month < 12; month++) {
    const monthName = format(new Date(2020, month), 'LLLL', { locale })
    months.push(capitalizeFirstLetter(monthName))
  }
  return months
}

const russianMonths = getMonthsArray(ru)

const YearGrid: React.FC<
  YearGridProps & { minDate?: Date; maxDate?: Date }
> = ({ selectedYear, onSelect, minDate, maxDate }) => {
  const minYear = minDate ? minDate.getFullYear() : null
  const maxYear = maxDate ? maxDate.getFullYear() : null

  const yearRefs = useRef([])
  yearRefs.current = []

  const currentYear = new Date().getFullYear()

  const addToRefs = (el) => {
    if (el && !yearRefs.current.includes(el)) {
      yearRefs.current.push(el)
    }
  }

  useEffect(() => {
    const selectedYearIndex = yearsRange.indexOf(selectedYear)
    const selectedYearRef = yearRefs.current[selectedYearIndex]
    if (selectedYearRef) {
      selectedYearRef.scrollIntoView({
        block: 'center',
      })
    }
  }, [selectedYear])

  return (
    <SimpleGrid
      columns={4}
      spacing={1}
      overflowY="auto"
      maxH="220px"
      p={2}
      className="custom-scrollbar"
    >
      {yearsRange.map((year) => {
        const isDisabled =
          (minYear && year < minYear) || (maxYear && year > maxYear)

        const getBg = () => {
          if (year === selectedYear) return '#2c5282'
          else if (isDisabled) return '#cfcfcf'
          else return 'gray.50'
        }
        return (
          <Box
            ref={addToRefs}
            key={year}
            p={1}
            bg={getBg()}
            opacity={isDisabled ? 0.3 : 1}
            color={year === selectedYear && !isDisabled ? 'white' : 'black'}
            textAlign="center"
            borderRadius="md"
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
            position="relative"
            border="2px solid transparent"
            _hover={{
              border: isDisabled
                ? '2px solid transparent'
                : '2px solid #2c5282',
            }}
            onClick={() => {
              if (!isDisabled) {
                onSelect(year)
              }
            }}
          >
            {year}
            {year === currentYear && !isDisabled && (
              <Box
                position="absolute"
                top="1"
                right="1"
                bg="green.500"
                borderRadius="full"
                w="8px"
                h="8px"
              />
            )}
          </Box>
        )
      })}
    </SimpleGrid>
  )
}

const MonthGrid: React.FC<MonthGridProps> = ({
  selectedMonth,
  onSelect,
  minDate,
  maxDate,
  selectedYear,
}) => {
  const minMonth =
    minDate && minDate.getFullYear() === selectedYear ? minDate.getMonth() : 0
  const maxMonth =
    maxDate && maxDate.getFullYear() === selectedYear ? maxDate.getMonth() : 11

  return (
    <SimpleGrid
      columns={3}
      spacing={1}
      overflowY="auto"
      maxH="220px"
      p={2}
      className="custom-scrollbar"
    >
      {months.map((month, index) => {
        const isDisabled = index < minMonth || index > maxMonth
        const getBg = () => {
          if (index === selectedMonth && !isDisabled) return '#2c5282'
          else if (isDisabled) return '#cfcfcf'
          else return 'gray.50'
        }

        return (
          <Box
            key={month}
            p={1}
            bg={getBg()}
            opacity={isDisabled ? 0.3 : 1}
            color={index === selectedMonth && !isDisabled ? 'white' : 'black'}
            textAlign="center"
            borderRadius="md"
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
            border="2px solid transparent"
            _hover={{
              border: isDisabled
                ? '2px solid transparent'
                : '2px solid #2c5282',
            }}
            onClick={() => {
              if (!isDisabled) {
                onSelect(index)
              }
            }}
          >
            {month}
          </Box>
        )
      })}
    </SimpleGrid>
  )
}

const maskData = {
  yyyy: [/\d/, /\d/, /\d/, /\d/],
  'dd.MM.yyyy': [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
  'MM.yyyy': [/\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
}

export const DatePickerField = <TFieldValues extends FieldValues>(
  props: DatePickerInputProps<TFieldValues>,
) => {
  const {
    control,
    name,
    placeholder = '',
    register,
    errors,
    watchedFields,
    isRequired,
    theme,
    format = 'V',
    mask = maskData[format],
    smallErrorTextInside = false,
    showMonthYearPicker = false,
    showFullMonthYearPicker = false,
    showYearPicker = false,
    showOnlyYearPicker = false,
    showTimeSelect = false,
    isClearable = false,
    inline = false,
    isReadOnly = false,
    minDate = null,
    maxDate = null,
    size = 'xs',
    type = 'text',
    years = yearsRange,
    months = russianMonths,
    ...rest
  } = props

  const [yearPopoverOpen, setYearPopoverOpen] = useState<boolean>(false)
  const [monthPopoverOpen, setMonthPopoverOpen] = useState<boolean>(false)

  const className = classes[theme] || classes.holydays

  const handleCalendarClose = () => {
    setYearPopoverOpen(false)
    setMonthPopoverOpen(false)
  }

  const handleOutsideClick = () => {
    const clickEvent = new MouseEvent('mousedown', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    document.dispatchEvent(clickEvent)
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange, value } = field

        const parsedDate = moment(value, 'DD.MM.YYYY').toDate()

        const handleKeyDown = (event: KeyboardEvent) => {
          if (isReadOnly) event.preventDefault()
        }

        const fieldValue = getNestedValue(watchedFields, name)
        const fieldError = getNestedValue(errors, name)
        const isTouched =
          fieldValue !== undefined && fieldValue !== '' && fieldValue !== null
        const isValid = isTouched && !fieldError

        return (
          <div className={clsx(className)}>
            <DatePicker
              minDate={minDate}
              maxDate={maxDate}
              onCalendarClose={handleCalendarClose}
              disabledKeyboardNavigation
              showMonthYearPicker={showMonthYearPicker}
              showFullMonthYearPicker={showFullMonthYearPicker}
              showYearPicker={showYearPicker}
              selected={
                typeof value === 'string'
                  ? parsedDate
                  : (value && new Date(value)) || null
              }
              startDate={moment().toDate()}
              onChange={onChange}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                increaseYear,
                decreaseYear,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
                prevYearButtonDisabled,
                nextYearButtonDisabled,
              }) => {
                return (
                  <Flex
                    justifyContent="center"
                    h={'100%'}
                    alignItems={'center'}
                    position={'relative'}
                  >
                    {showOnlyYearPicker ? (
                      <>
                        <Box
                          w={'260px'}
                          background={'#fff'}
                          borderRadius={'8px'}
                          boxShadow={'0px 8px 15px rgba(0, 0, 0, 0.18)'}
                          border={'1px solid #e2e8f0'}
                          position={'absolute'}
                          top={'0px'}
                          left={'-5px'}
                        >
                          <YearGrid
                            minDate={minDate}
                            maxDate={maxDate}
                            selectedYear={getYear(date)}
                            onSelect={(year) => {
                              const newDate = new Date(date.setFullYear(year))
                              changeYear(year)
                              onChange(newDate)
                              setYearPopoverOpen(false)
                              handleOutsideClick()
                            }}
                          />
                        </Box>
                      </>
                    ) : (
                      <Flex
                        w={'100%'}
                        justifyContent={'space-between'}
                        p={'0 10px'}
                      >
                        <Flex>
                          <Flex
                            position={'relative'}
                            alignItems={'center'}
                            mr={'10px'}
                          >
                            {/* <Box
                      onClick={() => {
                        const newDate = new Date(date)
                        newDate.setFullYear(newDate.getFullYear() - 1)
                        changeYear(newDate.getFullYear())
                        onChange(newDate)
                      }}
                      color="#b6b8b9"
                      _hover={{ color: '#2c5282' }}
                      transition={'color 0.2s'}
                      padding={'5px'}
                      cursor="pointer"
                      fontSize={'18px'}
                    >
                      <FaChevronLeft />
                    </Box> */}
                            <Popover
                              isOpen={yearPopoverOpen}
                              onClose={() => setYearPopoverOpen(false)}
                            >
                              <PopoverTrigger>
                                <Button
                                  w="70px"
                                  size="sm"
                                  onClick={() => setYearPopoverOpen(true)}
                                >
                                  {getYear(date)}
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent
                                shadow={'lg'}
                                style={{
                                  position: 'absolute',
                                  top: '-130px',
                                  left: showOnlyYearPicker ? '-105px' : '-78px',
                                }}
                              >
                                <PopoverBody p={'0'} m={'0'}>
                                  <IconButton
                                    zIndex={10}
                                    rounded={'full'}
                                    colorScheme="red"
                                    fontSize="8px"
                                    aria-label="Close popover"
                                    icon={<CloseIcon />}
                                    size="xs"
                                    position="absolute"
                                    right="-2"
                                    top="-2"
                                    onClick={() => setYearPopoverOpen(false)}
                                  />
                                  <YearGrid
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    selectedYear={getYear(date)}
                                    onSelect={(year) => {
                                      const newDate = new Date(
                                        date.setFullYear(year),
                                      )
                                      changeYear(year)
                                      onChange(newDate)
                                      setYearPopoverOpen(false)
                                    }}
                                  />
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>

                            {/* <Box
                      onClick={() => {
                        const newDate = new Date(date)
                        newDate.setFullYear(newDate.getFullYear() + 1)
                        changeYear(newDate.getFullYear())
                        onChange(newDate)
                      }}
                      color="#b6b8b9"
                      _hover={{ color: '#2c5282' }}
                      transition={'color 0.2s'}
                      padding={'5px'}
                      cursor="pointer"
                      fontSize={'18px'}
                    >
                      <FaChevronRight />
                    </Box> */}
                          </Flex>
                          {!showOnlyYearPicker && (
                            <Flex position={'relative'} alignItems={'center'}>
                              <Popover
                                isOpen={monthPopoverOpen}
                                onClose={() => setMonthPopoverOpen(false)}
                              >
                                <PopoverTrigger>
                                  <Button
                                    w="70px"
                                    size="sm"
                                    onClick={() => setMonthPopoverOpen(true)}
                                  >
                                    {months[getMonth(date)]}
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                  shadow={'lg'}
                                  style={{
                                    position: 'absolute',
                                    top: '-130px',
                                    left: '-155px',
                                  }}
                                >
                                  <PopoverBody p={'0'} m={'0'}>
                                    <IconButton
                                      zIndex={10}
                                      rounded={'full'}
                                      fontSize="8px"
                                      colorScheme="red"
                                      aria-label="Close popover"
                                      icon={<CloseIcon />}
                                      size="xs"
                                      position="absolute"
                                      right="-2"
                                      top="-2"
                                      onClick={() => setMonthPopoverOpen(false)}
                                    />
                                    <MonthGrid
                                      minDate={minDate}
                                      maxDate={maxDate}
                                      selectedYear={getYear(date)}
                                      selectedMonth={getMonth(date)}
                                      onSelect={(monthIndex) => {
                                        const newDate = new Date(
                                          date.setMonth(monthIndex),
                                        )
                                        changeMonth(monthIndex)
                                        onChange(newDate)
                                        setMonthPopoverOpen(false)
                                      }}
                                    />
                                  </PopoverBody>
                                </PopoverContent>
                              </Popover>
                            </Flex>
                          )}
                        </Flex>

                        <Flex alignItems={'center'}>
                          <Box
                            opacity={prevMonthButtonDisabled ? 0 : 1}
                            onClick={() => {
                              if (!showOnlyYearPicker) {
                                if (prevMonthButtonDisabled) return
                                const newDate = new Date(date)
                                newDate.setMonth(newDate.getMonth() - 1)
                                changeMonth(newDate.getMonth())
                                onChange(newDate)
                              }

                              if (showOnlyYearPicker) {
                                const newDate = new Date(date)
                                newDate.setFullYear(newDate.getFullYear() - 1)
                                changeYear(newDate.getFullYear())
                                onChange(newDate)
                              }
                            }}
                            color="#b6b8b9"
                            _hover={{ color: '#2c5282' }}
                            transition={'color 0.2s'}
                            padding={'7px'}
                            cursor={
                              prevMonthButtonDisabled ? 'auto' : 'pointer'
                            }
                            fontSize={'14px'}
                          >
                            <FaChevronLeft />
                          </Box>

                          <Box
                            opacity={nextMonthButtonDisabled ? 0 : 1}
                            onClick={() => {
                              if (!showOnlyYearPicker) {
                                if (nextMonthButtonDisabled) return
                                const newDate = new Date(date)
                                newDate.setMonth(newDate.getMonth() + 1)
                                changeMonth(newDate.getMonth())
                                onChange(newDate)
                              }
                              if (showOnlyYearPicker) {
                                const newDate = new Date(date)
                                newDate.setFullYear(newDate.getFullYear() + 1)
                                changeYear(newDate.getFullYear())
                                onChange(newDate)
                              }
                            }}
                            color="#b6b8b9"
                            _hover={{ color: '#2c5282' }}
                            transition={'color 0.2s'}
                            padding={'7px'}
                            cursor={
                              nextMonthButtonDisabled ? 'auto' : 'pointer'
                            }
                            fontSize={'14px'}
                          >
                            <FaChevronRight />
                          </Box>
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                )
              }}
              customInput={
                <MaskedTextInput
                  disabled={true}
                  placeholder={placeholder}
                  type="text"
                  mask={mask}
                  readOnly={isReadOnly}
                  onKeyDown={handleKeyDown}
                  render={(ref, props) => {
                    const {
                      onChange,
                      name,
                      onKeyDown,
                      onFocus,
                      onClick,
                      onBlur,
                    } = props

                    return (
                      <Flex
                        flexDirection={'column'}
                        position="relative"
                        w={'100%'}
                      >
                        <Input
                          disabled={false}
                          isDisabled={false}
                          isInvalid={!!fieldError}
                          size={size}
                          borderRadius={'6px'}
                          onBlur={onBlur}
                          mr="5px"
                          w="100%"
                          textOverflow={'ellipsis'}
                          ref={ref}
                          borderColor={isValid ? '#16b164' : '#e2e8f0'}
                          boxShadow={
                            isValid ? getStyles[size].successBoxShadow : 'none'
                          }
                          {...props}
                          placeholder={placeholder}
                          onChange={onChange}
                          isReadOnly={isReadOnly}
                          name={name}
                          onFocus={onFocus}
                          onKeyDown={onKeyDown}
                        />
                        {isRequired && !isTouched && (
                          <Box
                            position="absolute"
                            right={getStyles[size].errorRight}
                            top={getStyles[size].errorIconTop}
                            color="gray.400"
                            fontSize={getStyles[size].fontSize}
                          >
                            <Tooltip
                              id={`${name}-${placeholder}-warning`}
                              content="Данное поле является обязательным для заполнения"
                              place="top"
                            >
                              <Box
                                w={size === 'sm' ? '14px' : '18px'}
                                color={fieldError ? 'red.500' : '#e5e6e5'}
                              >
                                <IoIosWarning />
                              </Box>
                            </Tooltip>
                          </Box>
                        )}
                        {fieldError && smallErrorTextInside && (
                          <Flex
                            alignItems="center"
                            color="red.600"
                            position={'absolute'}
                            top={'-6px'}
                            left={'5px'}
                            background={'#fff'}
                            borderRadius={'20px'}
                            paddingRight={'5px'}
                            zIndex={'9'}
                          >
                            <Text
                              fontSize={getStyles[size].errorFontSize}
                              fontWeight={'700'}
                              ml="5px"
                              lineHeight={'11px'}
                              whiteSpace={'nowrap'}
                            >
                              {fieldError.message}
                            </Text>
                          </Flex>
                        )}
                        {isValid && isRequired && (
                          <Flex
                            alignItems="center"
                            color="green.600"
                            position={'absolute'}
                            right={'6px'}
                            top={getStyles[size].successIconTop}
                          >
                            <Tooltip
                              id={`${name}-${placeholder}-success`}
                              content="Данное поле успешно прошло валидацию"
                              place="top"
                            >
                              <AiOutlineCheck />
                            </Tooltip>
                          </Flex>
                        )}
                      </Flex>
                    )
                  }}
                />
              }
              dateFormat={format}
              {...rest}
              {...field}
              showPopperArrow={false}
              locale={ru}
              shouldCloseOnSelect
              isClearable
              popperContainer={({ children }) => (
                <CalendarContainer theme={theme} children={children} />
              )}
            />
          </div>
        )
      }}
    />
  )
}
