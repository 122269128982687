import { useCallback, useEffect } from 'react'
import { Controller } from 'react-hook-form'

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Text,
  Box,
  FormControl,
  useToast,
} from '@chakra-ui/react'

import { useGetUsersPermissions } from 'entities/usersPermissions'
import { toasts } from 'shared/utils'

import {
  SelectInput,
  TextLabel,
  TextLabelTitle,
  TextTitle,
  TextValueTitle,
} from 'shared/ui'

import {
  accessDict,
  accessTypeDict,
  inheritanceTypeDict,
} from 'shared/dictionary'

import {
  EditUserPermissionFormData,
  useEditUserPermissionForm,
} from './UseEditUserPermissionForm'

import { useUpdateUserPermission } from '../models/services/useUpdateUserPermission'
import { accordionModulesSettings } from '../models/utils/gteAcessData'

// misc
import { ChevronDownIcon } from '@chakra-ui/icons'

interface SelectOption {
  value: string
  label: string
}

interface Module {
  moduleName: string
  label: string
  data: SelectOption[]
  inheritanceData: SelectOption[] | null
}

interface HandleChangeArgs {
  moduleName: keyof EditUserPermissionFormData
  option: { value?: string } | null
  type: AccessType
}

interface EditUserPermissionProps {
  userId: string
  isCurrentUserAbleToEditAccess: boolean
  title?: string
}

enum AccessType {
  access = 'access',
  inheritance = 'inheritance',
}

const ItemText = ({ children }) => {
  return <TextTitle size="small">{children}</TextTitle>
}

export const EditUserPermission: React.FC<EditUserPermissionProps> = (
  props,
) => {
  const {
    userId,
    isCurrentUserAbleToEditAccess,
    title = 'Доcтупы пользователя',
  } = props

  const { userModuleRights, transformedData } = useGetUsersPermissions({
    userId,
    enabled: !!userId,
  })

  const { handleSubmit, errors, reset, control, watch } =
    useEditUserPermissionForm()

  const toast = useToast()

  const { mutate: updateUserPermissions, isLoading: isLoadingUpdate } =
    useUpdateUserPermission()

  useEffect(() => {
    if (userModuleRights && transformedData) {
      const initialValues = Object.keys(transformedData).reduce(
        (acc, moduleName) => {
          const moduleData = transformedData[moduleName]
          acc[moduleName] = {
            access: moduleData.access,
            inheritance: moduleData.inheritance,
          }
          return acc
        },
        {},
      )
      reset(initialValues)
    }
  }, [userModuleRights, reset])

  const fieldsValues = watch()

  const handleResetForm = () => reset()

  const handleChange = useCallback(
    async ({ moduleName, option, type }: HandleChangeArgs) => {
      const newValue = option ? option.value : ''

      const originalModuleData = transformedData[moduleName]?.originalData

      if (!originalModuleData) {
        console.error('No original data found for module:', moduleName)
        toasts.error({
          title: 'Произошла ошибка',
          description: 'Данные для модулей ошибочные',
        })
        handleResetForm()
        return
      }

      const accessIdValue =
        type === AccessType.access ? newValue : fieldsValues[moduleName].access
      const inheritTypeIdValue =
        type === AccessType.inheritance
          ? newValue
          : fieldsValues[moduleName].inheritance

      const updateData = {
        accessId: accessTypeDict[accessIdValue],
        inheritTypeId: inheritanceTypeDict[inheritTypeIdValue],
        moduleRightsId: originalModuleData.module_rights_id,
        moduleName: originalModuleData.module_name,
        userId,
      }

      const updatePromise = new Promise((resolve, reject) => {
        updateUserPermissions({
          ...updateData,
          successAction: () => resolve('Данные успешно обновлены'),
          errorAction: () => reject(new Error('Ошибка обновления данных')),
        })
      })

      toast.promise(updatePromise, {
        loading: {
          title: 'Обновление данных...',
          description: 'Пожалуйста, подождите.',
          position: 'bottom-left',
          // @ts-ignore
          status: 'success',
        },
        success: {
          title: 'Успешно!',
          description: (
            <p>
              Доступы в модуле{' '}
              <span
                style={{ fontWeight: 'bold', fontSize: '18px' }}
              >{`${originalModuleData.module_name}`}</span>{' '}
              обновлены
            </p>
          ),

          position: 'bottom-left',
          // @ts-ignore
          status: 'info',
        },
        error: { title: 'Ошибка!', description: 'Не удалось обновить данные.' },
      })
    },
    [updateUserPermissions, transformedData, fieldsValues],
  )

  const generateFormField = (module, isLast: boolean) => {
    return (
      <Box
        borderBottom={isLast ? 'none' : '1px solid #dfe0eb'}
        pb={'5px'}
        mt={'8px'}
        key={module.moduleName}
      >
        <ItemText>{module.label}</ItemText>
        <Flex>
          <Flex alignItems={'center'} mt={'5px'} mr={'25px'} w={'350px'}>
            <FormControl
              id={`${module.moduleName}.access`}
              isInvalid={!!errors[module.moduleName]?.access}
            >
              <Box ml={'10px'}>
                <Flex alignItems={'flex-start'} flexDir={'column'}>
                  <TextLabelTitle mb={'2px'} mr={'8px'}>
                    Досутп:
                  </TextLabelTitle>

                  {!isCurrentUserAbleToEditAccess ? (
                    <TextValueTitle pt={'8px'}>
                      {fieldsValues[module.moduleName]?.access
                        ? module.data.find(
                            (option) =>
                              option.value ===
                              fieldsValues[module.moduleName]?.access,
                          )?.label
                        : 'Не задано'}
                    </TextValueTitle>
                  ) : (
                    <Controller
                      // @ts-ignore
                      name={`${module.moduleName}.access`}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => (
                        <SelectInput
                          size={'sm'}
                          isReadOnly={
                            isLoadingUpdate || !isCurrentUserAbleToEditAccess
                          }
                          data={module.data}
                          placeholder="Выберите тип разрешения"
                          value={module.data.find(
                            (option) => option.value === value,
                          )}
                          onChange={(option) => {
                            if (
                              option.value ===
                              module.data.find(
                                (option) => option?.value === value,
                              )?.value
                            ) {
                              return
                            }
                            onChange(option ? option.value : '')
                            handleChange({
                              moduleName: module.moduleName,
                              type: AccessType.access,
                              option,
                            })
                          }}
                          error={!!error}
                        />
                      )}
                    />
                  )}
                </Flex>

                <Text color="red.600">
                  {errors[module.moduleName]?.access?.message}
                </Text>
              </Box>
            </FormControl>
          </Flex>
          <>
            {module.inheritanceData &&
              fieldsValues?.[module.moduleName]?.access ===
                accessDict.partial_access && (
                <>
                  <Flex
                    position={'relative'}
                    top={'2px'}
                    alignItems={'baseline'}
                    flexDirection={'column'}
                  >
                    <Flex w={'350px'}>
                      <FormControl
                        id={`${module.moduleName}.inheritance`}
                        isInvalid={!!errors[module.moduleName]?.inheritance}
                      >
                        <TextLabelTitle mb={'5px'}>
                          Вид доступа по наследованию:
                        </TextLabelTitle>

                        {!isCurrentUserAbleToEditAccess ? (
                          <Text fontSize={'18px'}>
                            {fieldsValues[module.moduleName]?.inheritance
                              ? module.inheritanceData.find(
                                  (option) =>
                                    option.value ===
                                    fieldsValues[module.moduleName]
                                      ?.inheritance,
                                )?.label
                              : 'Не задано'}
                          </Text>
                        ) : (
                          <Controller
                            // @ts-ignore
                            name={`${module.moduleName}.inheritance`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <SelectInput
                                size={'sm'}
                                isReadOnly={
                                  isLoadingUpdate ||
                                  !isCurrentUserAbleToEditAccess
                                }
                                data={module.inheritanceData}
                                placeholder="Выберите тип разрешения"
                                isClearable={false}
                                value={module.inheritanceData.find(
                                  (option) => option.value === value,
                                )}
                                onChange={(option) => {
                                  if (
                                    option.value ===
                                    module.inheritanceData.find(
                                      (option) => option?.value === value,
                                    )?.value
                                  ) {
                                    return
                                  }

                                  onChange(option ? option.value : '')
                                  handleChange({
                                    moduleName: module.moduleName,
                                    type: AccessType.inheritance,
                                    option,
                                  })
                                }}
                                error={!!errors.counter_agents?.inheritance}
                              />
                            )}
                          />
                        )}

                        <Text color="red.600">
                          {errors[module.moduleName]?.inheritance?.message}
                        </Text>
                      </FormControl>
                    </Flex>
                  </Flex>
                </>
              )}
          </>
        </Flex>
      </Box>
    )
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(null)}>
        <TextTitle size="small"> {title} </TextTitle>
        <Box
          mt="15px"
          borderLeft={'1px solid #dfe0eb'}
          borderRight={'1px solid #dfe0eb'}
        >
          <>
            <Accordion allowMultiple width="100%" rounded="lg" display="block">
              {accordionModulesSettings.map((tab) => (
                <AccordionItem key={tab.tabLabel}>
                  <AccordionButton
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    bg={'#ebeeff3c'}
                  >
                    <Text fontSize="14px" fontWeight={'700'}>
                      {tab.tabLabel}
                    </Text>
                    <ChevronDownIcon />
                  </AccordionButton>

                  <AccordionPanel pb={'4px'}>
                    {tab.modules.map((module, index) => {
                      return generateFormField(
                        module,
                        index === tab.modules.length - 1,
                      )
                    })}
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </>
        </Box>
      </form>
    </Box>
  )
}
