import { CellProps } from 'react-table'

import { BasicDateColumn, BasicSumColumn, BasicTitle } from 'shared/ui'

export const TableStructure = () => {
  const structure = [
    {
      title: () => <BasicTitle>День</BasicTitle>,
      accessor: 'date',
      disableSortBy: true,
      minWidth: 80,
      width: 80,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return <BasicDateColumn>{row.original.date}</BasicDateColumn>
      },
    },
    {
      title: () => <BasicTitle>Час</BasicTitle>,
      accessor: 'hour_number',
      disableSortBy: true,
      minWidth: 80,
      width: 80,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return <BasicSumColumn>{row.original.hour_number}</BasicSumColumn>
      },
    },
  ]

  return structure
}
