import { Box, Flex, Text } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

interface FormInputBlockProps {
  children: ReactNode
  title: string
  value?: string | number
  width?: string
  height?: string
  titleFontSize?: string
  titleFontWeight?: string
  titleWidth?: string
  titleColor?: string
  edit?: boolean
  allowEdit?: boolean
  valueFontSize?: string
  valueFontWeight?: string
  valueWidth?: string
  valueColor?: string
  topTitle?: boolean
  valueComponent?: ReactNode
}

export const FormInputBlock: React.FC<FormInputBlockProps> = (props) => {
  const {
    children,
    edit = false,
    allowEdit = false,
    title = '',
    value = '',
    titleWidth = '120px',
    titleColor = '#718096',
    titleFontSize = '14px',
    titleFontWeight = '500',
    width = '100%',
    height = '32px',
    valueWidth = '100%',
    valueColor = '#000000a3',
    valueFontSize = '14px',
    valueFontWeight = '600',
    valueComponent = null,
    topTitle = false,
  } = props

  return (
    <Flex
      // border={'1px solid blue'}
      w={width}
      display={'flex'}
      alignItems={topTitle ? 'flex-start' : 'center'}
      minHeight={height}
      position={'relative'}
      flexDirection={topTitle ? 'column' : 'row'}
    >
      <Box
        w={titleWidth}
        maxW={titleWidth}
        minW={titleWidth}
        position={'relative'}
        mb={topTitle ? '5px' : 0}
        // border="1px solid red"
        display={'flex'}
        alignSelf={'flex-start'}
        mt={'4px'}
      >
        <Text
          // border={'1px solid green'}
          color={titleColor}
          fontSize={titleFontSize}
          fontWeight={titleFontWeight}
        >
          {title}
        </Text>
      </Box>
      <Box w={'100%'} display={'flex'} alignItems={'center'}>
        {edit && allowEdit ? (
          <Flex gap={2} w={'100%'}>
            {children}
          </Flex>
        ) : (
          <>
            {valueComponent ? (
              <>{valueComponent}</>
            ) : (
              <Flex
                w={valueWidth}
                h={'100%'}
                alignItems={'center'}
                position={'relative'}
              >
                <Text
                  fontSize={valueFontSize}
                  fontWeight={valueFontWeight}
                  color={valueColor}
                  ml={'13px'}
                  top={'-1px'}
                >
                  {value}
                </Text>
              </Flex>
            )}
          </>
        )}
      </Box>
    </Flex>
  )
}
