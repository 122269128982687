import { useQuery } from 'react-query'

import { JuridicalPersonResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { ReferenceBookService } from 'shared/services'

interface JuridicalPersonsData {
  value: string
  label: string
}

interface UseGetAllJuridicalPersonsResult {
  data: JuridicalPersonResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  juridicalPersonsData: JuridicalPersonsData[] | undefined
}

interface UseAllJuridicalPersons {
  enabled: boolean
}

export function useGetAllJuridicalPersons({
  enabled,
}: UseAllJuridicalPersons): UseGetAllJuridicalPersonsResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    JuridicalPersonResponse[]
  >({
    queryKey: [queryKeys.referenceBooks.activityAreas.allJuridicalPersons],
    queryFn: () =>
      ReferenceBookService.getAllJuridicalPersons().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  const juridicalPersonsData =
    data?.map((jur) => ({
      value: jur.jur_person_id,
      label: jur.juridical_person,
    })) || []

  return { data, isError, isFetching, isLoading, juridicalPersonsData }
}
