import React from 'react'
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'

import { BaseLayout, SettingLayout } from 'app/layouts'

import {
  HomePage,
  NotFound,
  UserEditPage,
  UsersSettingPage,
  CalendarPage,
  RegionsPage,
  TestPage,
  CompaniesPage,
  CreateCompanyPage,
  UserProfilePage,
  PriceCategoriesPage,
  CounteragentsContractsPage,
  PriceMonitoringPage,
  ImportDataPage,
  PriceDataPage,
  TariffsPage,
  GPDataPage,
} from 'pages'

import { AuthProvider } from '../authProvider'

import {
  SETTINGS_ROUTE,
  COMPANIES,
  USERS,
  REFERENCE_BOOKS_ROUTE,
  CALENDAR,
  REGIONS,
  COUNTER_PARTIES,
  AGREEMENT,
  PRICE_CATEGORIES,
  TEST,
  CREATE_COMPANY_ROUTE,
  ROLES,
  USER_PROFILE_ROUTE,
  PRICES_ROUTE,
  MONITORING,
  IMPORT_DATA,
  PRICE_DATA,
  TARIFFS,
  GP_DATA,
} from 'shared/constants'

import { PrivateRouteGate } from '../accessProvider'
import { accessDict, modulesDict } from 'shared/dictionary'

export const AppRouter: React.FC = (): React.ReactElement => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="" element={<BaseLayout />}>
            <Route index element={<HomePage />} />

            <Route
              path={TEST}
              element={
                <PrivateRouteGate roles={[ROLES.SUPERADMIN]}>
                  <TestPage />
                </PrivateRouteGate>
              }
            />

            <Route
              path={`${SETTINGS_ROUTE}`}
              element={<SettingLayout type="settings" />}
            >
              <Route
                path={USERS}
                element={
                  <PrivateRouteGate roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
                    <UsersSettingPage />
                  </PrivateRouteGate>
                }
              />

              <Route
                path={`${USERS}/:id`}
                element={
                  <PrivateRouteGate roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
                    <UserEditPage />
                  </PrivateRouteGate>
                }
              />
              <Route
                path={COMPANIES}
                element={
                  <PrivateRouteGate
                    permissions={[
                      accessDict.full_access,
                      accessDict.read,
                      accessDict.update_read,
                    ]}
                    module={modulesDict.companies}
                  >
                    <CompaniesPage />
                  </PrivateRouteGate>
                }
              />

              <Route
                path={CREATE_COMPANY_ROUTE}
                element={
                  <PrivateRouteGate
                    permissions={[accessDict.full_access]}
                    module={modulesDict.companies}
                  >
                    <CreateCompanyPage />
                  </PrivateRouteGate>
                }
              />
            </Route>

            <Route path={USER_PROFILE_ROUTE} element={<UserProfilePage />} />

            <Route
              path={`${REFERENCE_BOOKS_ROUTE}`}
              element={<SettingLayout type="directory" />}
            >
              <Route
                path={CALENDAR}
                element={
                  <PrivateRouteGate
                    permissions={[accessDict.read, accessDict.update_read]}
                    module={modulesDict.reference_books}
                  >
                    <CalendarPage />
                  </PrivateRouteGate>
                }
              />

              <Route
                path={REGIONS}
                element={
                  <PrivateRouteGate
                    permissions={[accessDict.read, accessDict.update_read]}
                    module={modulesDict.reference_books}
                  >
                    <RegionsPage />
                  </PrivateRouteGate>
                }
              />

              <Route
                path={PRICE_CATEGORIES}
                element={
                  <PrivateRouteGate
                    permissions={[accessDict.read, accessDict.update_read]}
                    module={modulesDict.reference_books}
                  >
                    <PriceCategoriesPage />
                  </PrivateRouteGate>
                }
              />

              <Route
                path={COUNTER_PARTIES}
                element={
                  <PrivateRouteGate
                    permissions={[accessDict.read, accessDict.update_read]}
                    module={modulesDict.reference_books}
                  >
                    <CounteragentsContractsPage />
                  </PrivateRouteGate>
                }
              />

              <Route path={AGREEMENT} element={<p>AGREEMENT</p>} />
            </Route>

            <Route
              path={`${PRICES_ROUTE}`}
              element={<SettingLayout type="prices" />}
            >
              <Route
                path={MONITORING}
                element={
                  <PrivateRouteGate
                    permissions={[accessDict.read, accessDict.full_access]}
                    module={modulesDict.prices}
                  >
                    <PriceMonitoringPage />
                  </PrivateRouteGate>
                }
              />
              <Route
                path={IMPORT_DATA}
                element={
                  <PrivateRouteGate
                    permissions={[accessDict.full_access]}
                    module={modulesDict.prices}
                  >
                    <ImportDataPage />
                  </PrivateRouteGate>
                }
              />
              <Route
                path={PRICE_DATA}
                element={
                  <PrivateRouteGate
                    permissions={[accessDict.read, accessDict.full_access]}
                    module={modulesDict.prices}
                  >
                    <PriceDataPage />
                  </PrivateRouteGate>
                }
              />
              <Route
                path={TARIFFS}
                element={
                  <PrivateRouteGate
                    permissions={[accessDict.read, accessDict.full_access]}
                    module={modulesDict.prices}
                  >
                    <TariffsPage />
                  </PrivateRouteGate>
                }
              />
              <Route
                path={GP_DATA}
                element={
                  <PrivateRouteGate
                    permissions={[accessDict.read, accessDict.full_access]}
                    module={modulesDict.prices}
                  >
                    <GPDataPage />
                  </PrivateRouteGate>
                }
              />
            </Route>
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </Router>
  )
}
