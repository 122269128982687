import { Box, Button, Text } from '@chakra-ui/react'

import { Period } from 'widgets/prices'
import { Slice } from 'entities/prices'
import { useDownloadReport } from '../models/downloadReportService'

// misc
import { HiOutlineDocumentArrowDown } from 'react-icons/hi2'

interface DownloadReportProps {
  isDisabled: boolean
  gs_activity_areas_id: string
  date_period: string
  report_type: Period
  slice?: Slice
}

export const DownloadReport: React.FC<DownloadReportProps> = (props) => {
  const { isDisabled, gs_activity_areas_id, date_period, slice } = props

  const { mutate: downloadReport, isLoading } = useDownloadReport()

  const reportTypes = {
    [Period.byMonth]: 'svnc_by_month',
    [Period.byHour]: 'svnc_by_hour',
    [Period.peak]: 'peak',
  }

  const reportNames = {
    [Period.byMonth]: 'СВНЦЭМ АТС (месяц)',
    [Period.byHour]: 'СВНЦЭМ АТС (почасовые)',
    [Period.peak]: 'часы АТС',
  }

  const svncType = {
    [Slice.dateHour]: 'date_hour',
    [Slice.hourDate]: 'hour_date',
    [Slice.row]: 'column',
  }

  const handleDownloadReport = () => {
    downloadReport({
      svnc_type: slice ? svncType[slice] : null,
      gs_activity_areas_id,
      date_period,
      report_type: reportTypes[props.report_type],
      reportName: reportNames[props.report_type],
      successAction: () => null,
      failureAction: () => null,
    })
  }

  return (
    <>
      <Button
        mr="10px"
        position={'relative'}
        size={'xs'}
        display={'flex'}
        alignItems={'center'}
        onClick={handleDownloadReport}
        isDisabled={isDisabled}
        isLoading={isLoading}
        border={'1px solid #cfc6c6'}
        _hover={{ border: '1px solid #16b164' }}
      >
        <Box fontSize={'18px'} mr={'5px'} color={'green'}>
          <HiOutlineDocumentArrowDown />
        </Box>
        <Text position={'relative'}> Скачать отчет в Excel</Text>
      </Button>
    </>
  )
}
