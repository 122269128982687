import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'

import {
  SubjectsList,
  EnergySystemsList,
  PriceZonesList,
  ActivityAreas,
} from 'widgets'

export const RegionsPage: React.FC = () => {
  return (
    <Tabs>
      <TabList p={'10px'} pb={0}>
        <Tab>
          <Text fontSize={'18px'}>Ценовые зоны</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>ОЭС</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Субъекты РФ</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Зоны деятельности ГП</Text>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding={0}>
          <PriceZonesList />
        </TabPanel>
        <TabPanel padding={0}>
          <EnergySystemsList />
        </TabPanel>
        <TabPanel padding={0}>
          <SubjectsList />
        </TabPanel>
        <TabPanel padding={0}>
          <ActivityAreas />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
