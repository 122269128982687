export enum Slice {
  dateHour,
  hourDate,
  row,
}

export enum Type {
  br,
  rcb,
  brMinus,
  brPlus,
}

export const typeMap = {
  'СВНЦЭ БР': Type.br,
  'СВНЦЭ РСВ': Type.rcb,
  'СВНЦЭ БР-': Type.brMinus,
  'СВНЦЭ БР+': Type.brPlus,
}
