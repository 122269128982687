import { AxiosError } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'
import { ReferenceBookService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DeleteSubject {
  subject_id: string
  region_name: string
  successAction: () => void
}
export function useDeleteSubject(): {
  mutate: UseMutateFunction<void, AxiosError, DeleteSubject, unknown>
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation<void, AxiosError, DeleteSubject>(
    ({ subject_id }: DeleteSubject) =>
      ReferenceBookService.deleteSubject({ subject_id }).then(() => {}),
    {
      onSuccess: (_, variables: DeleteSubject) => {
        if (variables.successAction) variables.successAction()

        toasts.success({
          title: 'Субькет удален',
          description: `Субъект ${variables.region_name}  успешно удален`,
        })
        queryClient.invalidateQueries([
          queryKeys.referenceBooks.subjects.allSubjects,
        ])
      },
      onError: (error: ExtendedAxiosError) => {
        toasts.error({
          title: 'Произошла ошибка',
          description: error.response.data.detail ||  'Субъект не был удален',
        })
      },
    },
  )

  return { mutate, isLoading }
}
