import { CellProps } from 'react-table'

import {
  BasicCommonColumn,
  BasicDateColumn,
  BasicSumColumn,
  BasicTitle,
} from 'shared/ui'

export const TableStructure = () => {
  const structure = [
    {
      title: () => <BasicTitle>Тип цены</BasicTitle>,
      accessor: 'price_short_name',
      disableSortBy: true,
      minWidth: 80,
      width: 80,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return (
          <BasicCommonColumn>{row.original.price_short_name}</BasicCommonColumn>
        )
      },
    },
    {
      title: () => <BasicTitle>Цена</BasicTitle>,
      accessor: 'price',
      disableSortBy: true,
      minWidth: 80,
      width: 80,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return <BasicSumColumn>{row.original.price}</BasicSumColumn>
      },
    },
  ]

  return structure
}
