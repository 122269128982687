import { useSearchParams } from 'react-router-dom'
import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'

import { DataViewATCHours, DataViewSVNCEM } from 'widgets/prices'

export const PriceDataPage = () => {
  const [searchParams] = useSearchParams()
  const reportType = searchParams.get('report_type')
  const defaultIndex = reportType ? (reportType === 'svnc' ? 0 : 1) : 1

  return (
    <Tabs defaultIndex={defaultIndex}>
      <TabList p={'10px'} pb={0}>
        <Tab>
          <Text fontSize={'18px'}>СВНЦЭМ</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Часы АТС</Text>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding={0}>
          <DataViewSVNCEM />
        </TabPanel>
        <TabPanel padding={0}>
          <DataViewATCHours />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
