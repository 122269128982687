import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'

import { ReferenceBookService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UpdateActivityAreaMutation {
  successAction: () => void
  gs_activity_areas_id: string
  area_name: string
  price_zone_id: string
  ue_system_id: string
  subject_id: string
  time_zone: string
}

export function useUpdateActivityArea(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    UpdateActivityAreaMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      gs_activity_areas_id,
      area_name,
      price_zone_id,
      ue_system_id,
      subject_id,
      time_zone,
    }: UpdateActivityAreaMutation) =>
      ReferenceBookService.updateActivityArea({
        gs_activity_areas_id,
        area_name,
        price_zone_id,
        ue_system_id,
        subject_id,
        time_zone,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: UpdateActivityAreaMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Зона деятельности обновлена`,
          })
        }
        queryClient.invalidateQueries([
          queryKeys.referenceBooks.activityAreas.detailActivityAreas,
          variables.gs_activity_areas_id,
        ])
        queryClient.invalidateQueries([
          queryKeys.referenceBooks.activityAreas.allActivityAreas,
        ])
      },
      onError: (error: ExtendedAxiosError) =>
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Зона деятельности не обновлена',
        }),
    },
  )
  return { mutate, isLoading }
}
