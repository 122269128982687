import React, { memo } from 'react'
import { Box } from '@chakra-ui/react'

import { GetDataViewSVNCEMByMonthData } from 'shared/models'
import { TableEntity } from './TableEntity'

interface IDMonthTableTableProps {
  tableData: GetDataViewSVNCEMByMonthData[]
}

export const MonthTable: React.FC<IDMonthTableTableProps> = memo(
  ({ tableData }) => {
    return (
      <Box margin={'-17px'} mt={'0px'}>
        <TableEntity tableData={tableData} />
      </Box>
    )
  },
)
