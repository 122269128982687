import React, { useMemo, useState, memo, Dispatch, SetStateAction } from 'react'

import {
  Box,
  Flex,
  Text,
  Button,
  Tabs,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react'

import { TransFSKTableType } from 'widgets/prices/transServise/types/types'
import {
  DownloadTariffsReport,
  EditTransServiceFSKNetworks,
  EditTransServiceFSLosses,
} from 'features/prices'

import {
  useGetTransServiceFSKNetworks,
  useGetTransServiceFSKLosses,
} from 'entities/prices'
import { useGetAllSubjects } from 'entities/referenceBooks'
import { DatePickerField, FormInputBlock, SelectInputForm } from 'shared/ui'
import { normalizeValues } from 'shared/utils'

import { useDataViewTransServiceForm } from './DataViewTransServiceForm'

// misc
import { HiOutlineTableCells } from 'react-icons/hi2'

interface DataViewTransServiceFSKProps {
  fskType: TransFSKTableType
}

export const DataViewTransServiceFSK: React.FC<DataViewTransServiceFSKProps> =
  memo(({ fskType }): React.ReactElement => {
    const [fetchEnabled, setFetchEnabled] = useState<{
      enabled: boolean
    }>({
      enabled: false,
    })

    const { subjectsData } = useGetAllSubjects({
      enabled: true,
    })

    const {
      register,
      handleSubmit,
      errors,
      reset,
      isDirty,
      watchedFields,
      control,
      setValue,
      setError,
      watch,
      isValid,
    } = useDataViewTransServiceForm()

    const yearNetworks = normalizeValues(
      watchedFields.date_period_networks,
      'date_only_year',
    )

    const yearLosses = normalizeValues(
      watchedFields.date_period_losses,
      'date_only_year',
    )

    const {
      data: networksData,
      isLoading: isNetworksLoading,
      isFetching: isNetworksFetching,
      refetch: refetchNetworks,
    } = useGetTransServiceFSKNetworks({
      enabled: fetchEnabled.enabled,
      date_period: yearNetworks,
    })

    const {
      data: lossesData,
      isLoading: isLossesLoading,
      isFetching: isLossesFetching,
      refetch: refetchLosses,
    } = useGetTransServiceFSKLosses({
      enabled: fetchEnabled.enabled,
      subject_id: watchedFields.subject_id,
      date_period: yearLosses,
    })

    const commonInputProps = useMemo(
      () => ({
        control,
        register,
        errors,
        watchedFields,
        size: 'sm' as 'sm',
        smallErrorTextInside: true,
        isRequired: true,
      }),
      [register, errors, watchedFields, control],
    )

    const isButtonDisabled: boolean = useMemo((): boolean => {
      if (fskType === TransFSKTableType.networks) {
        return !watchedFields.date_period_networks
      } else if (fskType === TransFSKTableType.losses)
        return !watchedFields.date_period_losses || !watchedFields.subject_id
      return true
    }, [
      fskType,
      watchedFields.date_period_networks,
      watchedFields.date_period_losses,
      watchedFields.subject_id,
    ])

    const handleFetchData = () => {
      if (fskType === TransFSKTableType.networks) refetchNetworks()
      if (fskType === TransFSKTableType.losses) refetchLosses()
    }

    const editSalesTransServiceFSKNetworkProps = {
      data: networksData,
      year: yearNetworks,
    }

    const editSalesTransServiceFSKLossesProps = {
      data: lossesData,
      year: yearLosses,
      subject_id: watchedFields.subject_id,
    }

    const downloadFSKNetworkReportProps = {
      year: yearNetworks,
      report_type: 'federal_grid_company_nm',
      isDisabled: !watchedFields.date_period_networks,
    }

    const downloadFSKLossesReportProps = {
      year: yearNetworks,
      subject_id: watchedFields.subject_id,
      report_type: 'federal_grid_company_ls',
      isDisabled: !watchedFields.date_period_losses,
    }

    return (
      <Box p="2">
        <form>
          <Box ml="10px">
            <Flex alignItems={'center'}>
              {fskType === TransFSKTableType.losses && (
                <>
                  <Box mr="25px" w={'150px'}>
                    <FormInputBlock
                      titleWidth={'60px'}
                      allowEdit={true}
                      title={'Период'}
                      edit={true}
                    >
                      <DatePickerField
                        isReadOnly
                        showOnlyYearPicker
                        showYearPicker
                        format="yyyy"
                        showMonthYearPicker
                        name={'date_period_losses'}
                        placeholder={'Период'}
                        type={'text'}
                        theme="yearPicker"
                        {...commonInputProps}
                      />
                    </FormInputBlock>
                  </Box>
                  <Box w={'480px'}>
                    <FormInputBlock
                      titleWidth={'90px'}
                      allowEdit={true}
                      edit
                      title={'Субъект РФ'}
                    >
                      <SelectInputForm
                        placeholder="Выберите Субъект РФ"
                        getOptionValue={(option) =>
                          option ? option.value : ''
                        }
                        name="subject_id"
                        data={subjectsData || []}
                        isClearable={true}
                        {...commonInputProps}
                      />
                    </FormInputBlock>
                  </Box>
                </>
              )}
              {fskType === TransFSKTableType.networks && (
                <Box mr="25px" w={'150px'}>
                  <FormInputBlock
                    titleWidth={'60px'}
                    allowEdit={true}
                    title={'Период'}
                    edit={true}
                  >
                    <DatePickerField
                      isReadOnly
                      showOnlyYearPicker
                      showYearPicker
                      format="yyyy"
                      showMonthYearPicker
                      name={'date_period_networks'}
                      placeholder={'Период'}
                      type={'text'}
                      theme="yearPicker"
                      {...commonInputProps}
                    />
                  </FormInputBlock>
                </Box>
              )}
            </Flex>
            <Box mt="20px">
              <Flex>
                <Box>
                  <Button
                    w={'135px'}
                    position={'relative'}
                    size={'xs'}
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={handleFetchData}
                    colorScheme="teal"
                    isDisabled={isButtonDisabled}
                    isLoading={
                      isNetworksLoading ||
                      isNetworksFetching ||
                      isLossesLoading ||
                      isLossesFetching
                    }
                  >
                    <Box fontSize={'18px'} mr={'5px'}>
                      <HiOutlineTableCells />
                    </Box>
                    <Text position={'relative'}>Показать</Text>
                  </Button>
                </Box>
              </Flex>
            </Box>
          </Box>

          {(networksData || lossesData) && (
            <Box
              mt="15px"
              p="2"
              border={'1px solid #dfe0eb'}
              borderRadius={'5px'}
            >
              <Tabs
                index={fskType === TransFSKTableType.networks ? 0 : 1}
                isManual
              >
                <TabPanels>
                  <TabPanel padding={0}>
                    <EditTransServiceFSKNetworks
                      {...editSalesTransServiceFSKNetworkProps}
                      downloadReportSlot={
                        <Box ml={'15px'}>
                          <DownloadTariffsReport
                            {...downloadFSKNetworkReportProps}
                          />
                        </Box>
                      }
                    />
                  </TabPanel>
                  <TabPanel padding={0}>
                    <EditTransServiceFSLosses
                      {...editSalesTransServiceFSKLossesProps}
                      downloadReportSlot={
                        <Box ml={'15px'}>
                          <DownloadTariffsReport
                            {...downloadFSKLossesReportProps}
                          />
                        </Box>
                      }
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          )}
        </form>
      </Box>
    )
  })
