import { useState, useCallback, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Box, Flex, Text, Button } from '@chakra-ui/react'
import { useDropzone, FileRejection } from 'react-dropzone'

import { Access, useAccess } from 'app/providers'

import { UploadExcelResponse } from 'shared/models'
import { DropBox, TextTitle } from 'shared/ui'
import { UploadExcelFiles, useUploadFiles } from 'features/prices'
import { toasts } from 'shared/utils'

import {
  UploadFilesSuccessAction,
  UploadFilesFailureAction,
} from 'features/prices/uploadFiles/models/uploadFilesService'

// misc
import { FaUpload } from 'react-icons/fa6'

import {
  HiMiniPlusCircle,
  HiMiniXMark,
  HiOutlineDocumentText,
  HiOutlineExclamationTriangle,
} from 'react-icons/hi2'

export const ImportData = () => {
  const { hasAccess } = useAccess()

  const [files, setFiles] = useState<File[]>([])
  const [rejections, setRejections] = useState([])
  const [fileStatuses, setFileStatuses] = useState<{
    [key: string]: {
      isLoaded?: boolean
      isLoading?: boolean
      isError?: string
      data?: UploadExcelResponse
    }
  }>({})
  const [openedCollapses, setOpenedCollapses] = useState<{
    [key: string]: boolean
  }>({})

  const isAnyFileLoading = useMemo(() => {
    return Object.values(fileStatuses).some((status) => status.isLoading)
  }, [fileStatuses])

  const { mutate } = useUploadFiles()

  const validTypes = useMemo(
    () => [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    [],
  )

  const excelFileValidator = useCallback(
    (file) => {
      if (!validTypes.includes(file.type)) {
        return {
          code: 'invalid-file-type',
          message:
            'Только файлы  Microsoft Excel можно загружать с расширением .xlsx и .xls',
        }
      }
      return null
    },
    [validTypes],
  )

  const updateFileStatus = useCallback((fileName, status) => {
    setFileStatuses((prevStatuses) => ({
      ...prevStatuses,
      [fileName]: { ...prevStatuses[fileName], ...status },
    }))
  }, [])

  const toggleCollapse = useCallback((index) => {
    setOpenedCollapses((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }, [])

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const newFiles = acceptedFiles.filter(
        (af) => !files.some((f) => f.name === af.name),
      )
      if (newFiles.length !== acceptedFiles.length) {
        const duplicates = acceptedFiles.filter((af) =>
          files.some((f) => f.name === af.name),
        )

        toasts.error({
          title: 'Произошла ошибка',
          description: `Файлы с именем "${duplicates.map(
            (f) => f.name,
          )}" уже добавлены.`,
        })
      }
      setFiles((prevFiles) => [...prevFiles, ...newFiles])
      setRejections(fileRejections)
    },
    multiple: true,
    validator: excelFileValidator,
  })

  const removeFile = useCallback((index) => {
    setFiles((currentFiles) => currentFiles.filter((_, i) => i !== index))
  }, [])

  const removeAllFiles = useCallback(() => {
    setFiles([])
    setRejections([])
    setFileStatuses({})
    setOpenedCollapses({})
  }, [])

  const handleUpload = useCallback(
    (file, force = false) => {
      const fileName = file.name
      updateFileStatus(fileName, { isLoading: true })

      const file_id = uuidv4()
      mutate({
        file_name: fileName,
        file_id: file_id,
        file,
        force,
        successAction: ({ data }: UploadFilesSuccessAction) => {
          updateFileStatus(fileName, {
            isLoading: false,
            isError: false,
            isLoaded: true,
            data,
          })
        },
        failureAction: ({ errorMessage, data }: UploadFilesFailureAction) => {
          updateFileStatus(fileName, {
            isLoading: false,
            isError: true,
            errorMessage,
            data,
          })
        },
      })
    },
    [mutate, updateFileStatus],
  )

  const handleUploadAllFiles = useCallback(() => {
    files.forEach((file) => {
      const status = fileStatuses[file.name]
      if (!status || (!status.isLoading && !status.isLoaded)) {
        handleUpload(file)
      }
    })
  }, [files, fileStatuses, handleUpload])

  const uploadFilesProps = {
    files,
    fileStatuses,
    openedCollapses,
    removeFile,
    handleUpload,
    toggleCollapse,
  }

  return (
    <Box p="2">
      <Flex>
        <Button
          mr="10px"
          position={'relative'}
          size={'xs'}
          bg="blue.700"
          color="white"
          display={'flex'}
          alignItems={'center'}
          onClick={() => open()}
          _hover={{
            bg: 'blue.600',
          }}
        >
          <Box fontSize={'18px'} mr={'5px'}>
            <HiMiniPlusCircle />
          </Box>
          <Text position={'relative'}>Добавить файлы</Text>
        </Button>

        <Button
          mr={'10px'}
          position={'relative'}
          size={'xs'}
          display={'flex'}
          onClick={removeAllFiles}
          isDisabled={files.length === 0}
        >
          <Box fontSize={'18px'}>
            <HiMiniXMark />
          </Box>
          <Text position={'relative'}>Очистить список</Text>
        </Button>

        <Button
          mr="10px"
          position={'relative'}
          size={'xs'}
          color="white"
          display={'flex'}
          alignItems={'center'}
          onClick={handleUploadAllFiles}
          colorScheme="blue"
          isDisabled={files.length === 0}
          isLoading={isAnyFileLoading}
        >
          <Box fontSize={'12px'} mr={'5px'}>
            <FaUpload />
          </Box>
          <Text position={'relative'}>Загрузить все файлы</Text>
        </Button>
      </Flex>
      <Box mt="15px">
        <DropBox getRootProps={getRootProps} isDragActive={isDragActive}>
          <input {...getInputProps()} />
        </DropBox>
      </Box>
      <TextTitle size="small" mt="25px">
        Список файлов
      </TextTitle>
      {files && files.length > 0 ? (
        <Box mt="15px">
          <Box>
            <Box
              border={'1px solid #dfe0eb'}
              borderRadius={'5px'}
              p={'10px'}
              mt="10px"
            >
              <UploadExcelFiles {...uploadFilesProps} />
            </Box>
          </Box>
        </Box>
      ) : (
        <Text mt="5px" ml={'10px'} fontSize="14px" color={'#4E5A70'}>
          Отсутствуют
        </Text>
      )}

      {rejections && rejections.length > 0 && (
        <Box>
          <Flex alignItems="center">
            <TextTitle size="small" mt="25px">
              <Flex alignItems={'center'}>
                <Box mr="5px" color={'red'}>
                  <HiOutlineExclamationTriangle />
                </Box>
                <p>Файлы не приняты:</p>
              </Flex>
            </TextTitle>
          </Flex>

          <Box
            border={'1px solid #dfe0eb'}
            borderRadius={'5px'}
            p={'10px'}
            mt="10px"
          >
            {rejections.map((rejection, index) => (
              <Box key={index}>
                <Flex alignItems="center">
                  <Box color="red" mr="5px">
                    <HiOutlineDocumentText />
                  </Box>
                  <Box key={index} color="red.500">
                    {rejection.file.path} -{' '}
                    {rejection.errors.map((e) => e.message).join(', ')}
                  </Box>
                </Flex>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}
