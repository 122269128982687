import { Box, Button, Text } from '@chakra-ui/react'

import { useDownloadReport } from '../models/downloadReportService'

// misc
import { HiOutlineDocumentArrowDown } from 'react-icons/hi2'

interface DownloadReportProps {
  isDisabled: boolean
  year: string
  report_type: string
  gs_activity_areas_id?: string
  subject_id?: string
}

export const DownloadTariffsReport: React.FC<DownloadReportProps> = (props) => {
  const { isDisabled, subject_id, year, report_type, gs_activity_areas_id } =
    props

  const { mutate: downloadReport, isLoading } = useDownloadReport()

  const reportTypes = {
    sales_allowances_gs: 'sales_allowances_gs',
    trans_svc_by_subjects: 'trans_svc_by_subjects',
    federal_grid_company_nm: 'federal_grid_company_nm',
    federal_grid_company_ls: 'federal_grid_company_ls',
  }

  const reportNames = {
    sales_allowances_gs: 'сбытовые надбавки ГП',
    trans_svc_by_subjects: 'услуги передаче по субъектам',
    federal_grid_company_nm: 'ФСК содержание сетей',
    federal_grid_company_ls: 'ФСК норматив потерь',
  }

  const handleDownloadReport = () => {
    downloadReport({
      gs_activity_areas_id,
      subject_id,
      year,
      table_type: reportTypes[report_type],
      reportName: reportNames[report_type],
      successAction: () => null,
      failureAction: () => null,
    })
  }

  return (
    <>
      <Button
        mr="10px"
        position={'relative'}
        size={'xs'}
        display={'flex'}
        alignItems={'center'}
        onClick={handleDownloadReport}
        isDisabled={isDisabled}
        isLoading={isLoading}
        border={'1px solid #cfc6c6'}
        _hover={{ border: '1px solid #16b164' }}
      >
        <Box fontSize={'18px'} mr={'5px'} color={'green'}>
          <HiOutlineDocumentArrowDown />
        </Box>
        <Text position={'relative'}> Скачать отчет в Excel</Text>
      </Button>
    </>
  )
}
