import { AxiosPromise } from 'axios'

import {
  UploadExcelResponse,
  GetDataViewSVNCEMByHourRequest,
  GetDataViewSVNCEMByHourResponse,
  GetDataViewSVNCEMByMonthRequest,
  GetDataViewSVNCEMByMonthResponse,
  DownloadReportRequest,
  GetDataViewATCHoursRequest,
  GetDataViewATCHoursResponse,
  GetAllACTemplatesResponse,
  CreateACTemplateRequest,
  UploadExcelATCRequest,
  UploadATCExcelResponse,
  UploadExcelRequest,
  UpdateACTemplateRequest,
  GetSalesMarkupsRequest,
  GetSalesMarkupsResponse,
  UpdateSalesMarkupsRequest,
  GetTransServicesRequest,
  GetTransServicesResponse,
  UpdateTransServicesRequest,
  DownloadTariffReportRequest,
  GetGPDataRequest,
  GetGPDataResponse,
  UpdateGPDataRequest,
  GetTransServicesFSKNetworksResponse,
  UpdateTransServicesFSKNetwoksRequest,
  GetTransServicesFSKLossesResponse,
  UpdateTransServicesFSKLossesRequest,
} from 'shared/models'

import { ServiceBase } from './serviceBase'

export class PricesServiceService extends ServiceBase {
  protected static BASE_PATH = 'prices'

  public static uploadExcelFile({
    file_id,
    file,
    force,
  }: UploadExcelRequest): AxiosPromise<UploadExcelResponse> {
    const formData = new FormData()
    formData.append('file', file)
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return this.post(
      `${this.BASE_PATH}/upload-excel/${file_id}${force ? '?force=true' : ''}`,
      formData,
      config,
    )
  }

  public static uploadExcelFileATC({
    file_id,
    report_type,
    gs_activity_areas_id,
    date,
    force = false,
  }: UploadExcelATCRequest): AxiosPromise<UploadATCExcelResponse> {
    return this.post(`${this.BASE_PATH}/get_data_by_parser`, {
      file_id,
      report_type,
      gs_activity_areas_id,
      date,
      force,
    })
  }

  public static getDataViewSVNCEMByHour({
    gs_activity_areas_id,
    date_period,
  }: GetDataViewSVNCEMByHourRequest): AxiosPromise<GetDataViewSVNCEMByHourResponse> {
    return this.get<GetDataViewSVNCEMByHourResponse>(
      `${this.BASE_PATH}/svnc_by_hour/${gs_activity_areas_id}?date_period=${date_period}`,
    )
  }

  public static getDataViewSVNCEMByMonth({
    gs_activity_areas_id,
    date_period,
  }: GetDataViewSVNCEMByMonthRequest): AxiosPromise<GetDataViewSVNCEMByMonthResponse> {
    return this.get<GetDataViewSVNCEMByMonthResponse>(
      `${this.BASE_PATH}/svnc_by_month/${gs_activity_areas_id}?date_period=${date_period}`,
    )
  }

  public static getDataViewATCHours({
    gs_activity_areas_id,
    date_period,
  }: GetDataViewATCHoursRequest): AxiosPromise<GetDataViewATCHoursResponse> {
    return this.get<GetDataViewATCHoursResponse>(
      `${this.BASE_PATH}/peak_hours/${gs_activity_areas_id}?date_period=${date_period}`,
    )
  }

  public static downloadReport({
    gs_activity_areas_id,
    date_period,
    report_type,
    svnc_type,
  }: DownloadReportRequest): AxiosPromise<Blob> {
    let url = `${this.BASE_PATH}/report_by_type/${gs_activity_areas_id}?date_period=${date_period}&report_type=${report_type}`
    if (svnc_type) url += `&svnc_type=${svnc_type}`
    return this.get<Blob>(url, {
      responseType: 'blob',
    })
  }

  public static downloadTariffReport({
    gs_activity_areas_id,
    year,
    table_type,
    subject_id,
  }: DownloadTariffReportRequest): AxiosPromise<Blob> {
    return this.post<Blob>(
      `${this.BASE_PATH}/downloads_table`,
      {
        year,
        table_type,
        ...(gs_activity_areas_id && { gs_activity_areas_id }),
        ...(subject_id && { subject_id }),
      },
      {
        responseType: 'blob',
      },
    )
  }

  public static getATCTemplates(): AxiosPromise<GetAllACTemplatesResponse[]> {
    return this.get<GetAllACTemplatesResponse[]>(
      `${this.BASE_PATH}/list_parser_templates`,
    )
  }

  public static createATCTemplates({
    template_name,
    data,
  }: CreateACTemplateRequest): AxiosPromise<CreateACTemplateRequest> {
    return this.post<CreateACTemplateRequest>(
      `${this.BASE_PATH}/create_parser_templates`,
      {
        template_name,
        data,
      },
    )
  }

  public static deleteATCTemplate({
    template_name,
  }: {
    template_name: string
  }): AxiosPromise<{ template_name: string }> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/parser_template/${template_name}`,
    )
  }

  public static updateATCTemplates({
    template_name,
    old_name,
    data,
  }: UpdateACTemplateRequest): AxiosPromise<UpdateACTemplateRequest> {
    return this.patch<UpdateACTemplateRequest>(
      `${this.BASE_PATH}/update_parser_template/${old_name}`,
      {
        template_name,
        data,
      },
    )
  }

  public static getSalesMarkups({
    gs_activity_areas_id,
    year,
  }: GetSalesMarkupsRequest): AxiosPromise<GetSalesMarkupsResponse> {
    return this.get<GetSalesMarkupsResponse>(
      `${this.BASE_PATH}/list_sales_allowances_gs/${gs_activity_areas_id}?year=${year}`,
    )
  }

  public static updateSalesMarkups({
    gs_activity_areas_id,
    data,
  }: UpdateSalesMarkupsRequest): AxiosPromise<UpdateSalesMarkupsRequest> {
    return this.post<UpdateSalesMarkupsRequest>(
      `${this.BASE_PATH}/create_sales_allowances_gs/${gs_activity_areas_id}`,
      {
        data,
      },
    )
  }

  public static getTransServices({
    subject_id,
    year,
  }: GetTransServicesRequest): AxiosPromise<GetTransServicesResponse> {
    return this.get<GetTransServicesResponse>(
      `${this.BASE_PATH}/list_trans_svc_by_subjects/${subject_id}?year=${year}`,
    )
  }

  public static getTransServicesFSKNetworks({
    year,
  }: {
    year: string
  }): AxiosPromise<GetTransServicesFSKNetworksResponse> {
    return this.get<GetTransServicesFSKNetworksResponse>(
      `${this.BASE_PATH}/list_federal_grid_company_nm?year=${year}`,
    )
  }

  public static getTransServicesFSKLosses({
    year,
    subject_id,
  }: {
    year: string
    subject_id: string
  }): AxiosPromise<GetTransServicesFSKLossesResponse> {
    return this.get<GetTransServicesFSKLossesResponse>(
      `${this.BASE_PATH}/list_federal_grid_company_ls/${subject_id}?year=${year}`,
    )
  }

  public static updateTransServicesFSKNetworks({
    data,
  }: UpdateTransServicesFSKNetwoksRequest): AxiosPromise<UpdateTransServicesFSKNetwoksRequest> {
    return this.post<undefined>(
      `${this.BASE_PATH}/create_federal_grid_company_nm`,
      {
        data,
      },
    )
  }

  public static updateTransServicesFSKLosses({
    data,
    subject_id,
  }: UpdateTransServicesFSKLossesRequest): AxiosPromise<UpdateTransServicesFSKLossesRequest> {
    return this.post<undefined>(
      `${this.BASE_PATH}/create_federal_grid_company_ls/${subject_id}`,
      {
        data,
      },
    )
  }

  public static updateTransServices({
    subject_id,
    data,
  }: UpdateTransServicesRequest): AxiosPromise<UpdateTransServicesRequest> {
    return this.post<undefined>(
      `${this.BASE_PATH}/create_trans_svc_by_subjects/${subject_id}`,
      {
        data,
      },
    )
  }

  public static getGPData({
    gs_activity_areas_id,
    year,
  }: GetGPDataRequest): AxiosPromise<GetGPDataResponse> {
    return this.get<GetGPDataResponse>(
      `${this.BASE_PATH}/list_data_gs/${gs_activity_areas_id}?year=${year}`,
    )
  }

  public static updateGPData({
    gs_activity_areas_id,
    data,
  }: UpdateGPDataRequest): AxiosPromise<UpdateGPDataRequest> {
    return this.post<UpdateGPDataRequest>(
      `${this.BASE_PATH}/create_data_gs/${gs_activity_areas_id}`,
      {
        data,
      },
    )
  }
}
