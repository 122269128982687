import React, { memo } from 'react'
import { Box } from '@chakra-ui/react'

import { GetTransServicesFSKLossesData } from 'shared/models'
import { TableEntity } from './TableEntity'

interface TableTableProps {
  tableData: GetTransServicesFSKLossesData[]
  editMode: boolean
}

export const TransServiceFSKLossesTable: React.FC<TableTableProps> = memo(
  ({ tableData, editMode }): JSX.Element => {
    return (
      <Box margin={'-17px'} mt={'0px'}>
        <TableEntity tableData={tableData} editMode={editMode} />
      </Box>
    )
  },
)
