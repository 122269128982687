import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'

import { ReferenceBookService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UpdateSubjectMutation {
  successAction: () => void
  region_code: string
  region_name: string
  subject_id: string
}

export function useUpdateSubject(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    UpdateSubjectMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ region_code, region_name, subject_id }: UpdateSubjectMutation) =>
      ReferenceBookService.updateSubject({
        subject_id,
        region_code,
        region_name,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: UpdateSubjectMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Данные Субъекта обновлены',
            description: `Данные Субъекта успешно обновлены`,
          })
        }
        queryClient.invalidateQueries([
          queryKeys.referenceBooks.subjects.allSubjects,
        ])
      },
      onError: (error: ExtendedAxiosError) =>
        toasts.error({
          title: 'Произошла ошибка',
          description: error.response.data.detail || 'Данные Субъекта не были обновлены',
        }),
    },
  )
  return { mutate, isLoading }
}
