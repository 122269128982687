import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { map, transform, omit } from 'lodash'
import { Box, Button, Flex, Text } from '@chakra-ui/react'

import { GetGPDataData } from 'shared/models'

import { tableDataSignal, isDataChangedSignal } from '../signals/signals'
import { GPDataTable } from './GPDataTable/GPDataTable'
import { useUpdateGPData } from '../models/services/editGPDataServie'

// misc
import { MdOutlineSave } from 'react-icons/md'
import { HiMiniXMark, HiOutlinePencilSquare } from 'react-icons/hi2'

interface EditGPDataProps {
  data: GetGPDataData[]
  year: string
  gs_activity_areas_id: string
}

export const EditGPData: React.FC<EditGPDataProps> = ({
  data,
  gs_activity_areas_id,
  year,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false)

  const { mutate: updateGPData, isLoading } = useUpdateGPData()

  const sanitizedData = useMemo(
    () =>
      map(data, (item) =>
        transform(item, (result, value, key) => {
          if (value === null) result[key] = ''
          else if (typeof value === 'string' && /^\d+(\.\d+)?$/.test(value))
            result[key] = value.replace('.', ',')
          else result[key] = value
        }),
      ),
    [data],
  )

  const [tableData, setTableData] = useState(sanitizedData as GetGPDataData[])

  useEffect(() => {
    setTableData(sanitizedData as GetGPDataData[])
    tableDataSignal.value = sanitizedData as GetGPDataData[]
  }, [sanitizedData])

  const toggleEditMode = () => setEditMode(!editMode)

  const handleCancelChanges = useCallback(() => {
    setTableData(sanitizedData as GetGPDataData[])
    setEditMode(false)
    isDataChangedSignal.value = false
  }, [sanitizedData])

  const saveChanges = useCallback(() => {
    if (!isDataChangedSignal.value) {
      setEditMode(false)
      return
    }
    setTableData(tableDataSignal.value)

    const newData = map(tableDataSignal.value, (item) =>
      transform(item, (result, value, key) => {
        result[key] =
          typeof value === 'string' && value.includes(',')
            ? value.replace(',', '.')
            : value === ''
            ? null
            : value
      }),
    )

    const convertedData = newData.filter((item: GetGPDataData) => item.edited)

    const dataToSend = convertedData.map((item) =>
      omit(item as GetGPDataData, [
        'edited',
        'isEdited',
        'user_name',
        'added_at',
      ]),
    )

    updateGPData({
      data: dataToSend as GetGPDataData[],
      date_period: year,
      gs_activity_areas_id: gs_activity_areas_id,
      successAction: () => {
        isDataChangedSignal.value = false
        setEditMode(false)
      },
    })
  }, [tableData])

  const salesMarkupsTableProps = useMemo(
    () => ({
      tableData,
      editMode,
      saveChanges,
      toggleEditMode,
    }),
    [tableData, editMode, saveChanges, toggleEditMode],
  )

  return (
    <Box maxW={'1000px'} position={'relative'}>
      <Box
        position={'absolute'}
        left={0}
        top={0}
        fontStyle="italic"
        fontSize={'14px'}
        fontWeight={'600'}
      >
        руб./МВт*ч
      </Box>
      {data && (
        <Box p={'17px'} pt={'0px'}>
          <>
            <Flex mb={'10px'} ml={'auto'} justifyContent={'flex-end'}>
              <Flex>
                {editMode && (
                  <Button
                    mr={'5px'}
                    size={'xs'}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    bg="teal.400"
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={saveChanges}
                    _hover={{
                      bg: 'teal.500',
                    }}
                  >
                    <Box fontSize={'22px'}>
                      <MdOutlineSave />
                    </Box>
                    Сохранить
                  </Button>
                )}

                {editMode ? (
                  <Button
                    mr={'5px'}
                    position={'relative'}
                    size={'xs'}
                    display={'flex'}
                    alignItems={'center'}
                    onClick={handleCancelChanges}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                  >
                    <Box fontSize={'18px'}>
                      <HiMiniXMark />
                    </Box>
                    <Text position={'relative'}>Отмена</Text>
                  </Button>
                ) : (
                  <Button
                    mr="5px"
                    position={'relative'}
                    size={'xs'}
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={() => setEditMode(true)}
                    colorScheme="blue"
                    isDisabled={isLoading}
                    isLoading={isLoading}
                  >
                    <Box fontSize={'22px'} mr={'5px'}>
                      <HiOutlinePencilSquare />
                    </Box>
                    <Text position={'relative'}>Редактировать</Text>
                  </Button>
                )}
              </Flex>
            </Flex>

            <GPDataTable {...salesMarkupsTableProps} />
          </>
        </Box>
      )}
    </Box>
  )
}
