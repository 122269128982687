import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation } from 'react-query'
import { saveAs } from 'file-saver'

import { ExtendedAxiosError, UploadExcelResponse } from 'shared/models'
import { PricesServiceService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DownloadTariffReport {
  successAction: () => void
  failureAction: () => void
  year: string
  table_type: string
  reportName: string
  gs_activity_areas_id?: string
  subject_id?: string
}

export function useDownloadReport(): {
  mutate: UseMutateFunction<
    AxiosResponse<Blob>,
    AxiosError,
    DownloadTariffReport,
    unknown
  >
  isLoading: boolean
} {
  const { mutate, isLoading } = useMutation(
    ({
      gs_activity_areas_id,
      subject_id,
      table_type,
      year,
    }: DownloadTariffReport) =>
      PricesServiceService.downloadTariffReport({
        gs_activity_areas_id,
        year,
        table_type,
        subject_id,
      }),
    {
      onSuccess: (
        response: AxiosResponse<Blob>,
        variables: DownloadTariffReport,
      ) => {
        saveAs(
          response.data,
          `${variables.year} Отчёт ${variables.reportName}.xlsx`,
        )

        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Файл успешно загружен`,
          })
        }
      },
      onError: (error: ExtendedAxiosError) => {
        const errorMessage =
          error.response?.data.detail || 'Загрузка файла не удалась'
        toasts.error({ title: 'Произошла ошибка', description: errorMessage })
      },
    },
  )

  return { mutate, isLoading }
}
