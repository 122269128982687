import React, { useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'

import {
  DataViewSalesMarkups,
  DataViewTransService,
  DataViewTransServiceFSK,
} from 'widgets'

import {
  TransFSKTableType,
  TransType,
} from 'widgets/prices/transServise/types/types'

export const TariffsPage: React.FC = (): React.ReactElement => {
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [transType, setTransType] = useState<TransType>(TransType.byBoiler)
  const [fskType, setFSKType] = useState<TransFSKTableType>(
    TransFSKTableType.networks,
  )

  const handleTabsChange = (index) => setTabIndex(index)

  return (
    <Tabs index={tabIndex} onChange={handleTabsChange}>
      <TabList p={'10px'} pb={0}>
        <Tab>
          <Text fontSize={'18px'}>Сбытовые надбавки ГП</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Услуги по передачи</Text>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding={0}>
          <DataViewSalesMarkups />
        </TabPanel>
        <TabPanel padding={0}>
          <Box p="1">
            <Box ml="10px">
              <Box>
                <Flex alignItems={'center'} mt={'10px'}>
                  <Button
                    w={'135px'}
                    size="xs"
                    borderRadius={0}
                    borderLeftRadius={'5px'}
                    border={'1px solid #ececec'}
                    borderRight={'none'}
                    _hover={{ bg: '#3285d2', color: 'white' }}
                    bg={
                      transType === TransType.byBoiler ? '#2d70af' : '#f8f8f8'
                    }
                    color={transType === TransType.byBoiler ? 'white' : 'black'}
                    onClick={() => setTransType(TransType.byBoiler)}
                  >
                    Котловые
                  </Button>
                  <Button
                    w={'135px'}
                    size="xs"
                    borderRadius={0}
                    borderRightRadius={'5px'}
                    border={'1px solid #ececec'}
                    _hover={{ bg: '#3285d2', color: 'white' }}
                    bg={transType === TransType.byFSK ? '#2d70af' : '#f8f8f8'}
                    color={transType === TransType.byFSK ? 'white' : 'black'}
                    onClick={() => setTransType(TransType.byFSK)}
                  >
                    ФСК
                  </Button>
                  {transType === TransType.byFSK && (
                    <>
                      <Button
                        ml={'10px'}
                        w={'135px'}
                        size="xs"
                        borderRadius={0}
                        borderLeftRadius={'5px'}
                        border={'1px solid #ececec'}
                        borderRight={'none'}
                        _hover={{ bg: '#3285d2', color: 'white' }}
                        bg={
                          fskType === TransFSKTableType.networks
                            ? '#2d70af'
                            : '#f8f8f8'
                        }
                        color={
                          fskType === TransFSKTableType.networks
                            ? 'white'
                            : 'black'
                        }
                        onClick={() => setFSKType(TransFSKTableType.networks)}
                      >
                        Содержание сетей
                      </Button>
                      <Button
                        w={'135px'}
                        size="xs"
                        borderRadius={0}
                        borderRightRadius={'5px'}
                        border={'1px solid #ececec'}
                        _hover={{ bg: '#3285d2', color: 'white' }}
                        bg={
                          fskType === TransFSKTableType.losses
                            ? '#2d70af'
                            : '#f8f8f8'
                        }
                        color={
                          fskType === TransFSKTableType.losses
                            ? 'white'
                            : 'black'
                        }
                        onClick={() => setFSKType(TransFSKTableType.losses)}
                      >
                        Норматив потерь
                      </Button>
                    </>
                  )}
                </Flex>
              </Box>
            </Box>
          </Box>

          <Tabs index={transType === TransType.byBoiler ? 0 : 1} isManual>
            <TabPanels>
              <TabPanel padding={0}>
                <DataViewTransService />
              </TabPanel>
              <TabPanel padding={0}>
                <DataViewTransServiceFSK fskType={fskType} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
