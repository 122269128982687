import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import {
  ExtendedAxiosError,
  GetTransServicesFSKNetworksResponse,
} from 'shared/models'
import { PricesServiceService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UpdateTransServiceMutation
  extends GetTransServicesFSKNetworksResponse {
  successAction: () => void
  date_period: string
}

export function useUpdateTransServiceFSKNetwork(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    UpdateTransServiceMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ data }: UpdateTransServiceMutation) =>
      PricesServiceService.updateTransServicesFSKNetworks({
        data,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: UpdateTransServiceMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Таблица была успешно обновлена`,
          })
        }

        queryClient.invalidateQueries(
          [queryKeys.prices.transServiceFSKNetworks, variables.date_period],
          { refetchInactive: true },
        )
      },
      onError: (error: ExtendedAxiosError) =>
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Таблица не была обновлена',
        }),
    },
  )
  return { mutate, isLoading }
}
