import React, { memo, useMemo } from 'react'
import { groupBy, map, forEach } from 'lodash'
import { Box } from '@chakra-ui/react'

import { GetDataViewSVNCEMByHourData } from 'shared/models'
import { TableEntity } from './TableEntity'

interface IHourDateTableProps {
  tableData: GetDataViewSVNCEMByHourData[]
}

export const RowTable: React.FC<IHourDateTableProps> = memo(({ tableData }) => {
  const transformData = (data) => {
    const grouped = groupBy(data, (item) => `${item.date}_${item.hour_number}`)

    return map(grouped, (values, key) => {
      const entry = { date: values[0].date, hour_number: values[0].hour_number }
      forEach(values, (item) => {
        entry[item.price_short_name] = item.price
      })
      return entry
    })
  }

  const data = useMemo(() => transformData(tableData), [tableData])

  return (
    <div>
      <Box margin={'-17px'} mt={'0px'} maxW={'1060px'}>
        <TableEntity tableData={data} />
      </Box>
    </div>
  )
})
