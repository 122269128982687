import React from 'react'
import { Box, Flex } from '@chakra-ui/react'

import { GetDataViewSVNCEMByMonthData, UploadFileInfo } from 'shared/models'
import { EmptyDataMessage, UploadFileInfoView } from 'shared/ui'

import { MonthTable } from './MonthTable/MonthTable'

interface DataViewSVNCEMByMontProps {
  data: GetDataViewSVNCEMByMonthData[]
  downloadReportSlot?: JSX.Element
  upload_info: UploadFileInfo
}

export const DataViewSVNCEMByMont: React.FC<DataViewSVNCEMByMontProps> = ({
  data,
  downloadReportSlot,
  upload_info,
}) => {
  return (
    <Box maxW={'800px'}>
      <Flex mb="17px">
        {data && data?.length !== 0 && (
          <Flex ml="auto" mr={'-10px'} alignItems={'center'}>
            <Box mr={'15px'}>
              {upload_info && <UploadFileInfoView {...upload_info} />}
            </Box>
            {downloadReportSlot ? downloadReportSlot : null}
          </Flex>
        )}
      </Flex>
      {data && (
        <Box p={'17px'} pt={'0px'}>
          {Array.isArray(data) && data.length === 0 ? (
            <EmptyDataMessage />
          ) : (
            <>
              <MonthTable tableData={data} />
            </>
          )}
        </Box>
      )}
    </Box>
  )
}
