import React, { Dispatch, memo, useMemo, useState } from 'react'
import { Box, Button, Flex } from '@chakra-ui/react'
import { groupBy } from 'lodash'

import {
  GetDataViewSVNCEMByHourData,
  UploadFileInfo,
} from 'shared/models/services'
import { EmptyDataMessage, UploadFileInfoView } from 'shared/ui'

import { DateHourTable, HourDateTable, RowTable } from './components'
import { Slice, Type, typeMap } from '../types/types'

interface DataViewSVNCEMByHourProps {
  data: GetDataViewSVNCEMByHourData[]
  upload_info: UploadFileInfo
  downloadReportSlot?: JSX.Element
  slice: Slice
  setSlice: Dispatch<React.SetStateAction<Slice>>
  setType: Dispatch<React.SetStateAction<Type>>
  type: Type
}

interface ButtonConfig {
  label: string
  value: Slice | Type
  borderRadius: {
    borderLeftRadius?: string
    borderRightRadius?: string
    borderRight?: string
  }
}

export const DataViewSVNCEMByHour: React.FC<DataViewSVNCEMByHourProps> = memo(
  ({
    data,
    downloadReportSlot,
    slice,
    setSlice,
    setType,
    type,
    upload_info,
  }) => {
    const groupedData = useMemo(() => {
      const grouped = groupBy(data, 'price_short_name')
      const transformed = {}
      Object.keys(grouped).forEach((key) => {
        const enumKey = typeMap[key]
        transformed[enumKey] = grouped[key]
      })
      return transformed
    }, [data])

    const sliceButtons: ButtonConfig[] = [
      {
        label: 'Дата-час',
        value: Slice.dateHour,
        borderRadius: { borderLeftRadius: '5px', borderRight: 'none' },
      },
      { label: 'Час-дата', value: Slice.hourDate, borderRadius: {} },
      {
        label: 'Столбец',
        value: Slice.row,
        borderRadius: { borderRightRadius: '5px' },
      },
    ]

    const typeButtons: ButtonConfig[] = [
      {
        label: 'СВНЦЭ БР',
        value: Type.br,
        borderRadius: { borderLeftRadius: '5px', borderRight: 'none' },
      },
      { label: 'СВНЦЭ РСВ', value: Type.rcb, borderRadius: {} },
      { label: 'СВНЦЭ БР-', value: Type.brMinus, borderRadius: {} },
      {
        label: 'СВНЦЭ БР+',
        value: Type.brPlus,
        borderRadius: { borderRightRadius: '5px' },
      },
    ]

    const renderButton = (
      button: ButtonConfig,
      state: Slice | Type,
      setState: React.Dispatch<React.SetStateAction<Slice | Type>>,
    ) => (
      <Button
        key={button.label}
        w={'100px'}
        size="xs"
        borderRadius={0}
        bg={state === button.value ? '#2d70af' : '#f8f8f8'}
        color={state === button.value ? 'white' : 'black'}
        _hover={{ bg: '#eeeeee', color: 'black' }}
        onClick={() => setState(button.value)}
        {...button.borderRadius}
      >
        {button.label}
      </Button>
    )

    const tableData = groupedData[type]

    return (
      <Box>
        <Flex>
          <Box mr="15px">
            <Flex>
              {sliceButtons.map((button) =>
                renderButton(button, slice, setSlice),
              )}
            </Flex>
          </Box>

          <Box w={slice === Slice.row ? '750px' : '100%'}>
            <Flex>
              {slice !== Slice.row && (
                <>
                  {typeButtons.map((button) =>
                    renderButton(button, type, setType),
                  )}
                </>
              )}
              <Flex ml="auto" alignItems={'center'}>
                <Box mr={'15px'}>
                  {upload_info && <UploadFileInfoView {...upload_info} />}
                </Box>
                {downloadReportSlot ? downloadReportSlot : null}
              </Flex>
            </Flex>
          </Box>
        </Flex>
        <Box p={'17px'}>
          {data && (
            <>
              {Array.isArray(data) && data.length === 0 ? (
                <EmptyDataMessage />
              ) : (
                <>
                  {slice === Slice.dateHour && (
                    <DateHourTable tableData={tableData} />
                  )}
                  {slice === Slice.hourDate && (
                    <HourDateTable tableData={tableData} />
                  )}
                  {slice === Slice.row && <RowTable tableData={data} />}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    )
  },
)
