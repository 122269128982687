import { useQuery } from 'react-query'

import {
  ExtendedAxiosError,
  GetTransServicesData,
  GetTransServicesResponse,
} from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PricesServiceService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UseGetTransServiceResult {
  data: GetTransServicesData[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  refetch: () => void
}

interface UseGetTransService {
  enabled: boolean
  subject_id: string
  date_period: string
  successAction?: () => void
  errorAction?: () => void
}
export function useGetTransServiceService({
  enabled,
  subject_id,
  date_period,
  successAction,
  errorAction,
}: UseGetTransService): UseGetTransServiceResult {
  const { data, isError, isFetching, isLoading, refetch } =
    useQuery<GetTransServicesResponse>({
      queryKey: [queryKeys.prices.transService, subject_id, date_period],
      queryFn: () =>
        PricesServiceService.getTransServices({
          subject_id,
          year: date_period,
        }).then((response) => response.data),
      enabled,
      onError: (error: ExtendedAxiosError) => {
        if (errorAction) errorAction()
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error?.response?.data?.detail || 'Данные не были загружены',
        })
      },
      onSuccess: () => {
        if (successAction) successAction()
      },
    })

  return {
    data: data?.data,
    isError,
    isFetching,
    isLoading,
    refetch,
  }
}
