import { useState } from 'react'
import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { GPData } from 'widgets/prices'

export const GPDataPage: React.FC = (): React.ReactElement => {
  const [tabIndex, setTabIndex] = useState<number>(0)

  const handleTabsChange = (index) => setTabIndex(index)

  return (
    <Tabs index={tabIndex} onChange={handleTabsChange}>
      <TabList p={'10px'} pb={0}>
        <Tab>
          <Text fontSize={'18px'}>Данные ГП</Text>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding={0}>
          <GPData />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
