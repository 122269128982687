import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { UpdateACTemplateRequest, ExtendedAxiosError } from 'shared/models'
import { PricesServiceService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UpdateATCTemplateMutation extends UpdateACTemplateRequest {
  successAction: () => void
  old_name: string
}

export function useUpdateATCTemplate(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    UpdateATCTemplateMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      template_name,
      data,
      successAction,
      old_name,
    }: UpdateATCTemplateMutation) =>
      PricesServiceService.updateATCTemplates({
        old_name,
        template_name,
        data,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: UpdateATCTemplateMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Шаблон был успешно обновлен`,
          })
        }
        queryClient.invalidateQueries([queryKeys.prices.allATSTemplates])
      },
      onError: (error: ExtendedAxiosError) =>
        toasts.error({
          title: 'Произошла ошибка',
          description: error.response.data.detail || 'Шаблон не был обновлен',
        }),
    },
  )
  return { mutate, isLoading }
}
