import React, { memo } from 'react'
import { groupBy, map, fromPairs } from 'lodash'
import { Box } from '@chakra-ui/react'

import { GetDataViewSVNCEMByHourData } from 'shared/models'
import { TableEntity } from './TableEntity'

interface IHourDateTableProps {
  tableData: GetDataViewSVNCEMByHourData[]
}

export const HourDateTable: React.FC<IHourDateTableProps> = memo(
  ({ tableData }) => {
    const groupedByHour = groupBy(tableData, 'hour_number')

    const dataForTable = map(groupedByHour, (entries, hour) => {
      const hoursData = entries.reduce((acc, entry) => {
        acc[entry.date] = entry.price
        return acc
      }, {})

      return {
        hour,
        ...hoursData,
      }
    })

    return (
      <div>
        <Box margin={'-17px'} mt={'0px'}>
          <TableEntity tableData={dataForTable} />
        </Box>
      </div>
    )
  },
)
