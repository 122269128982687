import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export interface DataViewATCHoursForm {
  date_period?: string
  gs_activity_areas_id?: string
}

const SCHEMA_VALIDATION = yup.object().shape({
  date_period: yup.string().required('Период обязательное поле'),
  gs_activity_areas_id: yup
    .string()
    .required('Зона  деятельности обязательное поле'),
})

export const useDataViewATCHoursForm = () => {
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
    watch,
    setError,
  } = useForm<DataViewATCHoursForm>({
    resolver: yupResolver(SCHEMA_VALIDATION),
    mode: 'all',
    defaultValues: {
      date_period: null,
      gs_activity_areas_id: '',
    },
  })

  const watchedFields = watch()

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
    resetField,
    setValue,
    watchedFields,
    setError,
    watch,
  }
}
