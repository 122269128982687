import React from 'react'

import { DetailsCalculationPriceResponse } from 'shared/models'
import { ListView } from 'shared/ui'
import { DetailsCalculationPriceItem } from './DetailsCalculationPriceItem'

interface DetailsCalculationPriceProps {
  detailsCalculation: DetailsCalculationPriceResponse[]
}

export const DetailsCalculationPriceDataList: React.FC<
  DetailsCalculationPriceProps
> = (props): JSX.Element => {
  const { detailsCalculation } = props

  return (
    <ListView.Lists>
      {detailsCalculation.map((details) => (
        <DetailsCalculationPriceItem
          key={details.details_calculation_pc_id}
          details_calculation={details.details_calculation}
          details_calculation_pc_id={details.details_calculation_pc_id}
          code_id={details.code_id}
        />
      ))}
    </ListView.Lists>
  )
}
