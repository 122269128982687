import { useQuery } from 'react-query'

import {
  ExtendedAxiosError,
  GetDataViewSVNCEMByMonthData,
  GetDataViewSVNCEMByMonthResponse,
  UploadFileInfo,
} from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PricesServiceService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UseGetDataViewSVNCEMByMonthResult {
  data: GetDataViewSVNCEMByMonthData[] | undefined
  upload_info: UploadFileInfo | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  refetch: () => void
}

interface UseGetDataViewSVNCEMByMonth {
  enabled: boolean
  gs_activity_areas_id: string
  date_period: string
  successAction?: () => void
  errorAction?: () => void
}

export function useGetDataViewSVNCEMByMonth({
  enabled,
  gs_activity_areas_id,
  date_period,
  successAction,
  errorAction,
}: UseGetDataViewSVNCEMByMonth): UseGetDataViewSVNCEMByMonthResult {
  const { data, isError, isFetching, isLoading, refetch } =
    useQuery<GetDataViewSVNCEMByMonthResponse>({
      queryKey: [
        queryKeys.prices.dataViewSVNCEMByMont,
        gs_activity_areas_id,
        date_period,
      ],
      queryFn: () =>
        PricesServiceService.getDataViewSVNCEMByMonth({
          gs_activity_areas_id,
          date_period,
        }).then((response) => response.data),
      enabled,
      onError: (error: ExtendedAxiosError) => {
        if (errorAction) errorAction()
        toasts.error({
          title: 'Произошла ошибка',
          description: error.response.data.detail || 'Данные не были загружены',
        })
      },
      onSuccess: () => {
        if (successAction) successAction()
      },
    })

  return {
    data: data?.data,
    upload_info: data?.upload_info,
    isError,
    isFetching,
    isLoading,
    refetch,
  }
}
