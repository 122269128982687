import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export interface CreateATCTemplateFormData {
  template_name?: string
  items?: { file_type?: string; activity_zone?: string }[]
}

const SCHEMA_VALIDATION = yup.object().shape({
  template_name: yup.string().required('Имя обязательное поле'),
  items: yup
    .array()
    .of(
      yup.object().shape({
        file_type: yup.string().required('Тип файла обязателен'),
        activity_zone: yup.string().required('Зона деятельности обязательна'),
      }),
    )
    .min(1, 'Необходимо добавить хотя бы один элемент'),
})

export const useCreateATCTemplateForm = () => {
  const { register, handleSubmit, formState, control, watch, reset } =
    useForm<CreateATCTemplateFormData>({ 
      resolver: yupResolver(SCHEMA_VALIDATION),
      defaultValues: {
        items: [{ file_type: '', activity_zone: '' }],
      },
      mode: 'all',
    })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  })

  const watchedFields = watch()
  const items = watch('items')
  const { isValid, errors, isDirty } = formState

  return {
    register,
    handleSubmit,
    errors,
    isDirty,
    isValid,
    control,
    itemsFields: fields,
    appendItem: append,
    removeItem: remove,
    watchedFields,
    reset,
    items,
  }
}
