import { CellProps } from 'react-table'

import {
  BasicTitle,
  BasicSumColumn,
  BasicDateColumn,
  BasicCommonColumn,
} from 'shared/ui'
import { ColumnProps } from 'shared/ui/Table/UseColumns'

export const TableStructure = () => {
  const structure: ColumnProps[] = [
    {
      title: () => <BasicTitle>Дата</BasicTitle>,
      accessor: 'date',
      disableSortBy: true,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return <BasicDateColumn>{row.original.date}</BasicDateColumn>
      },
    },
    {
      title: () => <BasicTitle>Час</BasicTitle>,
      accessor: 'hour_number',
      disableSortBy: true,
      maxWidth: 80,
      width: '80px',
      CellComponent: ({ row }: CellProps<any, string>) => {
        return <BasicCommonColumn>{row.original.hour_number}</BasicCommonColumn>
      },
    },
    {
      title: () => <BasicTitle>СВНЦЭ РСВ</BasicTitle>,
      accessor: 'СВНЦЭ РСВ',
      disableSortBy: true,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return <BasicSumColumn>{row.original['СВНЦЭ РСВ']}</BasicSumColumn>
      },
    },
    {
      title: () => <BasicTitle>СВНЦЭ БР-</BasicTitle>,
      accessor: 'СВНЦЭ БР-',
      disableSortBy: true,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return <BasicSumColumn>{row.original['СВНЦЭ БР-']}</BasicSumColumn>
      },
    },
    {
      title: () => <BasicTitle>СВНЦЭ БР+</BasicTitle>,
      accessor: 'СВНЦЭ БР+',
      disableSortBy: true,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return <BasicSumColumn>{row.original['СВНЦЭ БР+']}</BasicSumColumn>
      },
    },
    {
      title: () => <BasicTitle>СВНЦЭ БР</BasicTitle>,
      accessor: 'СВНЦЭ БР',
      disableSortBy: true,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return <BasicSumColumn>{row.original['СВНЦЭ БР']}</BasicSumColumn>
      },
    },
  ]

  return structure
}
