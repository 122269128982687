import { ReactNode } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Divider,
} from '@chakra-ui/react'

interface BaseModalProps {
  header: string
  children: ReactNode
  id: string
  isOpen: boolean
  onClose: () => void
  size?: string
  isCentered?: boolean
}

export const BaseModal: React.FC<BaseModalProps> = ({
  header,
  children,
  id,
  isOpen,
  onClose,
  size = 'md',
  isCentered = false,
}) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        id={id}
        size={size}
        isCentered={isCentered}
      >
        <ModalOverlay />
        <ModalContent p="10px">
          <ModalHeader>{header}</ModalHeader>
          <Divider mb="5" />
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
