import React, { useCallback, useMemo } from 'react'
import {
  Box,
  Table,
  TableContainer,
  Th,
  Thead,
  Tr,
  Text,
  ResponsiveValue,
  Tbody,
  Td,
  Button,
  Flex,
  Collapse,
  Alert,
  AlertTitle,
} from '@chakra-ui/react'

import { useGetAllActivityAreas } from 'entities/referenceBooks'
import { Tooltip } from 'shared/ui'

// misc
import { FaUpload } from 'react-icons/fa6'

import {
  HiChevronDoubleDown,
  HiChevronDoubleUp,
  HiMiniArrowPath,
  HiMiniArrowTopRightOnSquare,
  HiMiniInformationCircle,
  HiOutlineArchiveBoxXMark,
  HiOutlineExclamationTriangle,
} from 'react-icons/hi2'
import { BiSolidErrorCircle } from 'react-icons/bi'
import { VscError } from 'react-icons/vsc'
import { FaCheckCircle } from 'react-icons/fa'
import { normalizeValues } from 'shared/utils'
import { PRICE_DATA_ROUTE } from 'shared/constants'

interface UploadExcelFilesFromATCProps {
  list: Array<{
    gs_activity_areas_id: string
    period: string
    type: string
    file_id: string
  }>
  fileStatuses: {
    [key: string]: any
  }
  openedCollapses: {
    [key: string]: boolean
  }
  removeFile: (index: number) => void
  handleUpload: ({
    force,
    file_id,
  }: {
    file_id: string
    report_type: string
    gs_activity_areas_id: string
    date: string
    force?: boolean
  }) => void
  toggleCollapse: (index: number) => void
}

const TableText: React.FC<{
  children?: React.ReactNode
  textAlign?: ResponsiveValue<'center' | 'left'>
}> = ({ children = null, textAlign = 'center' }) => {
  return (
    <Text fontSize={'12px'} textTransform="capitalize" textAlign={textAlign}>
      {children}
    </Text>
  )
}
export const UploadExcelFilesFromATC: React.FC<
  UploadExcelFilesFromATCProps
> = ({
  list,
  fileStatuses,
  openedCollapses,
  removeFile,
  handleUpload,
  toggleCollapse,
}): JSX.Element => {
  const { activityAreasData } = useGetAllActivityAreas({
    enabled: true,
  })

  const toggleCollapseMemoized = useCallback(
    (index) => toggleCollapse(index),
    [toggleCollapse],
  )

  const handleForceUploadMemoized = useCallback(
    ({ file_id, report_type, gs_activity_areas_id, date, force }) =>
      handleUpload({ file_id, report_type, gs_activity_areas_id, date, force }),
    [handleUpload],
  )

  const removeFileMemoized = useCallback(
    (index) => removeFile(index),
    [removeFile],
  )

  const handleOpenFile = (file) => {
    const period = file.data.period.date_from
    const gs_activity_areas_id = file.data.gs_activity_areas_id
    const report_type = file.data.report_type

    const url = `${PRICE_DATA_ROUTE}?period=${encodeURIComponent(
      period,
    )}&gs_activity_areas_id=${encodeURIComponent(
      gs_activity_areas_id,
    )}&report_type=${encodeURIComponent(report_type)}`

    window.open(url, '_blank')
  }

  const fileStatusColors = useMemo(() => {
    return list.reduce((acc, file) => {
      const status = fileStatuses[file.file_id]
      if (
        status?.isError &&
        status?.data?.status === 'warning' &&
        !status?.isLoading
      )
        acc[file.file_id] = '#fda026'
      else if (
        status?.isError &&
        status?.data?.status !== 'warning' &&
        !status?.isLoading
      )
        acc[file.file_id] = '#c92626'
      else if (status?.isLoading) acc[file.file_id] = 'yellow'
      else if (status?.isLoaded) acc[file.file_id] = '#21ba47'
      else acc[file.file_id] = '#2d53ad'
      return acc
    }, {})
  }, [list, fileStatuses])

  return (
    <Box position={'relative'}>
      <TableContainer maxH="55vh" overflowY="auto">
        <Table size="sm" variant="simple">
          <Box
            w={'100%'}
            h={'3px'}
            bg={'white'}
            position={'absolute'}
            top={'-1px'}
          />
          <Thead>
            <Tr>
              <TableTh width="1%">
                <TableText>Файл</TableText>
              </TableTh>
              <TableTh width="20%">
                <TableText>Тип</TableText>
              </TableTh>
              <TableTh width="30%">
                <TableText>Зона деятельности ГП</TableText>
              </TableTh>
              <TableTh width="20%">
                <TableText>Период</TableText>
              </TableTh>
              <TableTh width="10%">
                <TableText>Статус</TableText>
              </TableTh>
              <TableTh width="10%">
                <TableText>Действия</TableText>
              </TableTh>
            </Tr>
          </Thead>
          <Tbody>
            {list?.map((item, index) => {
              const fileErrored = fileStatuses[item.file_id]?.isError
              const fileWarning =
                fileStatuses[item.file_id]?.data?.status === 'warning'
              const fileLoaded = fileStatuses[item.file_id]?.isLoaded
              const fileLoading = fileStatuses[item.file_id]?.isLoading

              const activityArea = activityAreasData.find(
                (x) => x.value === item.gs_activity_areas_id,
              )?.label

              const type = {
                svnc: 'СВНЦЭМ',
                peak: 'Часы АТС',
              }

              return (
                <React.Fragment key={index}>
                  <Tr>
                    <Td>
                      <Flex alignItems="center">
                        {fileErrored ? (
                          <Tooltip
                            delayShow={500}
                            id={item.file_id}
                            content={
                              fileWarning
                                ? 'Данные уже есть в базе данных'
                                : 'Произршла ошибка файл не был загружен'
                            }
                            place="top"
                          >
                            <ErrorContainer
                              fileErrorIcon
                              warning={fileWarning}
                            />
                          </Tooltip>
                        ) : (
                          <>
                            {fileLoaded ? (
                              <Tooltip
                                delayShow={500}
                                id={item.file_id}
                                content="Файл успешно загружен"
                                place="top"
                              >
                                <Flex
                                  justifyContent={'center'}
                                  alignItems="center"
                                  w={'28px'}
                                  h={'24px'}
                                  color={'green'}
                                  mr="10px"
                                >
                                  <FaCheckCircle />
                                </Flex>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                delayShow={500}
                                id={String(item.gs_activity_areas_id)}
                                content="Загрузить файл"
                                place="top"
                              >
                                <Button
                                  isLoading={fileLoading}
                                  mr="10px"
                                  position={'relative'}
                                  size={'xs'}
                                  color="#1b5386"
                                  display={'flex'}
                                  alignItems={'center'}
                                  onClick={() =>
                                    handleUpload({
                                      force: false,
                                      file_id: item.file_id,
                                      report_type: item.type,
                                      gs_activity_areas_id:
                                        item.gs_activity_areas_id,
                                      date: item.period,
                                    })
                                  }
                                >
                                  <Box fontSize={'12px'}>
                                    <FaUpload />
                                  </Box>
                                </Button>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </Flex>
                    </Td>
                    <Td textAlign={'center'}>{type[item.type]}</Td>
                    <Td textAlign={'center'}>{activityArea || ''}</Td>
                    <Td textAlign={'center'}>
                      {normalizeValues(item.period, 'date')}
                    </Td>
                    <Td textAlign={'center'}>
                      {fileErrored && !fileLoading ? (
                        <Flex
                          alignItems={'center'}
                          cursor={fileWarning ? 'pointer' : 'default'}
                          justifyContent={'center'}
                          onClick={() => {
                            fileWarning && toggleCollapseMemoized(index)
                          }}
                        >
                          <Box
                            w={'10px'}
                            h={'10px'}
                            borderRadius={'50%'}
                            mr="5px"
                            bg={fileStatusColors[item.file_id]}
                          />
                          {fileWarning ? 'Предупреждение' : 'Ошибка'}
                          {fileWarning && (
                            <Box ml="3px">
                              {openedCollapses[index] ? (
                                <HiChevronDoubleUp />
                              ) : (
                                <HiChevronDoubleDown />
                              )}
                            </Box>
                          )}
                        </Flex>
                      ) : (
                        <Flex alignItems={'center'} justifyContent={'center'}>
                          <Box
                            w={'10px'}
                            h={'10px'}
                            borderRadius={'50%'}
                            mr="5px"
                            bg={fileStatusColors[item.file_id]}
                          />
                          {fileLoading
                            ? 'Загружается...'
                            : fileLoaded
                            ? 'Загружен'
                            : 'Готов к загрузке'}
                        </Flex>
                      )}
                    </Td>
                    <Td isNumeric>
                      <>
                        {fileErrored ? (
                          <>
                            {fileWarning ? (
                              <Box>
                                <Button
                                  isLoading={fileLoading}
                                  onClick={() =>
                                    handleForceUploadMemoized({
                                      file_id: item.file_id,
                                      report_type: item.type,
                                      gs_activity_areas_id:
                                        item.gs_activity_areas_id,
                                      date: item.period,
                                      force: true,
                                    })
                                  }
                                  mr="5px"
                                  size={'xs'}
                                  bg="orange.400"
                                  color="white"
                                  _hover={{
                                    bg: 'orange.500',
                                  }}
                                >
                                  <Box mr="3px">
                                    <HiMiniArrowPath />
                                  </Box>
                                  Повторно загрузить
                                </Button>
                              </Box>
                            ) : (
                              <Box>
                                <Button
                                  onClick={() => toggleCollapseMemoized(index)}
                                  mr="5px"
                                  size={'xs'}
                                  bg="red.500"
                                  color="white"
                                  _hover={{
                                    bg: 'red.600',
                                  }}
                                >
                                  <Box mr="3px">
                                    {openedCollapses[index] ? (
                                      <HiChevronDoubleUp />
                                    ) : (
                                      <HiChevronDoubleDown />
                                    )}
                                  </Box>
                                  {openedCollapses[index]
                                    ? 'Скрыть ошибки'
                                    : 'Посмотреть ошибки'}
                                </Button>
                              </Box>
                            )}
                          </>
                        ) : (
                          <>
                            {fileLoaded ? (
                              <Box>
                                <Button
                                  mr="5px"
                                  size={'xs'}
                                  bg="teal.400"
                                  color="white"
                                  _hover={{
                                    bg: 'teal.500',
                                  }}
                                  onClick={() =>
                                    handleOpenFile(fileStatuses[item.file_id])
                                  }
                                >
                                  <Box mr="3px">
                                    <HiMiniArrowTopRightOnSquare />
                                  </Box>
                                  Просмотр
                                </Button>
                              </Box>
                            ) : (
                              <Tooltip
                                delayShow={500}
                                id={String(index)}
                                content="Удалить файл"
                                place="top"
                              >
                                <Flex
                                  w={'100%'}
                                  justifyContent={'center'}
                                  ml="15px"
                                  className="icon-delete"
                                  fontSize="20px"
                                  cursor="pointer"
                                  color="#c2c2c2"
                                  _hover={{
                                    cursor: 'pointer',
                                    color: '#bc343f',
                                  }}
                                  onClick={() => removeFileMemoized(index)}
                                >
                                  <HiOutlineArchiveBoxXMark />
                                </Flex>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </>
                    </Td>
                  </Tr>
                  {openedCollapses[index] && !fileLoaded && (
                    <Tr>
                      <Td colSpan={6}>
                        <Collapse in={openedCollapses[index]} animateOpacity>
                          <Box maxW="100%" overflowX="auto">
                            <Alert
                              status={fileWarning ? 'warning' : 'error'}
                              p={'5px'}
                            >
                              <Box
                                mr="5px"
                                fontSize={'18px'}
                                color={fileWarning ? '#ffc06f' : '#e7a0b6'}
                              >
                                {<HiMiniInformationCircle />}
                              </Box>

                              <AlertTitle
                                fontSize={'12px'}
                                style={{
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {fileStatuses[item.file_id]?.errorMessage ||
                                  'Ошибка загрузки файла'}
                              </AlertTitle>
                            </Alert>
                          </Box>
                        </Collapse>
                      </Td>
                    </Tr>
                  )}
                </React.Fragment>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const TableTh: React.FC<{ width?: string; children: React.ReactNode }> = ({
  children,
  width,
}) => {
  return (
    <Th
      bg={'#f0f0f0'}
      _hover={{ bg: '#e9e9e9' }}
      width={width}
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}
    >
      {children}
    </Th>
  )
}

const ErrorContainer: React.FC<{
  errorTitle?: string
  fileErrorIcon?: boolean
  warning?: boolean
}> = ({ errorTitle, fileErrorIcon = false, warning = false }) => {
  return (
    <Flex
      justifyContent={'center'}
      alignItems="center"
      w={'100%'}
      h={'24px'}
      color={warning ? '#fda026' : 'red'}
      mr="10px"
      fontSize={'20px'}
    >
      <Tooltip
        delayShow={500}
        id={String(errorTitle)}
        content={errorTitle || ''}
        place="top"
      >
        <Box mr="5px">
          {fileErrorIcon ? (
            <>
              {warning ? (
                <HiOutlineExclamationTriangle />
              ) : (
                <BiSolidErrorCircle />
              )}
            </>
          ) : (
            <VscError />
          )}
        </Box>
      </Tooltip>
    </Flex>
  )
}
