import React, { memo } from 'react'
import { Box } from '@chakra-ui/react'

import { GetDataViewATCHoursData } from 'shared/models'
import { TableEntity } from './TableEntity'

interface IATCHoursTableProps {
  tableData: GetDataViewATCHoursData[]
}

export const ATCHoursTable: React.FC<IATCHoursTableProps> = memo(
  ({ tableData }) => {
    return (
      <Box margin={'-17px'} mt={'0px'}>
        <TableEntity tableData={tableData} />
      </Box>
    )
  },
)
