import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError, UploadATCExcelResponse } from 'shared/models'
import { PricesServiceService } from 'shared/services'
import { toasts } from 'shared/utils'

export interface UploadFilesATCSuccessAction {
  data: UploadATCExcelResponse
}

export interface UploadFilesFailureAction {
  errorMessage: string
  data?: UploadATCExcelResponse
}

interface UploadFiles {
  successAction: ({ data }: UploadFilesATCSuccessAction) => void
  failureAction: ({ errorMessage }: UploadFilesFailureAction) => void
  file_id: string
  report_type: string
  gs_activity_areas_id: string
  date: string
  force?: boolean
}

export function useUploadFilesATC(): {
  mutate: UseMutateFunction<
    AxiosResponse<UploadATCExcelResponse>,
    AxiosError,
    UploadFiles,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      file_id,
      report_type,
      force,
      gs_activity_areas_id,
      date,
    }: UploadFiles) =>
      PricesServiceService.uploadExcelFileATC({
        file_id,
        report_type,
        force,
        gs_activity_areas_id,
        date,
      }),
    {
      onSuccess: (
        data: AxiosResponse<UploadATCExcelResponse>,
        variables: UploadFiles,
      ) => {
        if (data.data?.error) {
          variables.failureAction({
            errorMessage: data.data?.error,
            data: data.data,
          })
          toasts.error({
            title: 'Произошла ошибка',
            description: data.data?.error,
          })
        } else {
          queryClient.setQueryData(
            [queryKeys.prices.handleUpload.uploadFileATC, variables.file_id],
            data.data,
          )

          if (variables.successAction)
            variables.successAction({ data: data.data })
        }
      },
      onError: (error: ExtendedAxiosError, variables) => {
        const errorMessage =
          error.response?.data.detail || 'Загрузка файла не удалась'

        variables.failureAction({ errorMessage })
        toasts.error({ title: 'Произошла ошибка', description: errorMessage })
      },
    },
  )
  return { mutate, isLoading }
}
