import React, { useCallback, useMemo } from 'react'
import {
  Box,
  Button,
  Collapse,
  Flex,
  Grid,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import {
  BaseModal,
  FormInputBlock,
  FormInputControl,
  NavigateBackButton,
  SelectInputForm,
  SubmitCancelButtons,
  TextTitle,
} from 'shared/ui'
import { useGetAllActivityAreas } from 'entities/referenceBooks'

import {
  CreateATCTemplateFormData,
  useCreateATCTemplateForm,
} from './CreateATCTemplateForm'

import { useCreateATCTemplate } from '../models/services/createATCTemplateServie'

// misc
import {
  HiMiniPlusCircle,
  HiMiniXMark,
  HiOutlineArchiveBoxXMark,
} from 'react-icons/hi2'

const reportTypes = {
  svnc: 'svnc',
  peak: 'peak',
}

const reportTypesTranslate = {
  svnc: 'СВНЦЭМ',
  peak: 'Часы АТС',
}

const reportTypesList = Object.keys(reportTypes).map((key) => ({
  value: reportTypes[key],
  label: reportTypesTranslate[key],
}))

interface CreateATCTemplateProps {
  setOpenedTemplates: React.Dispatch<React.SetStateAction<boolean>>
}

export const CreateATCTemplate: React.FC<CreateATCTemplateProps> = ({
  setOpenedTemplates,
}) => {
  const { isOpen, onToggle } = useDisclosure()

  const { activityAreasData = [] } = useGetAllActivityAreas({
    enabled: true,
  })
  const { mutate: createTemplate, isLoading } = useCreateATCTemplate()

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    watchedFields,
    isDirty,
    itemsFields,
    appendItem,
    removeItem,
    isValid,
    items,
  } = useCreateATCTemplateForm()

  const getAvailableActivityAreas = useCallback(
    (
      currentType: string,
      currentIndex: number,
    ): { value: string; label: string }[] => {
      const occupied = new Set(
        items
          .filter(
            (item, idx) =>
              item.file_type === currentType && idx !== currentIndex,
          )
          .map((item) => item.activity_zone),
      )
      return activityAreasData.filter((area) => !occupied.has(area.value))
    },
    [items, activityAreasData],
  )

  const getAvailableReportTypes = useCallback(
    (currentAreaId, currentIndex) => {
      const usedTypes = new Set(
        items
          .filter(
            (item, idx) =>
              item.activity_zone === currentAreaId && idx !== currentIndex,
          )
          .map((item) => item.file_type),
      )

      return reportTypesList.filter((type) => !usedTypes.has(type.value))
    },
    [items, reportTypesList],
  )

  const onSubmit = ({ template_name, items }: CreateATCTemplateFormData) => {
    createTemplate({
      successAction: () => {
        onToggle()
        reset()
      },
      template_name: template_name,
      data: items.map((item) => ({
        template_type: item.file_type,
        gs_activity_areas_id: item.activity_zone,
      })),
    })
  }

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  const disableAddButton = items.some(
    (item) => item.file_type === '' || item.activity_zone === '',
  )

  const handleResetForm = () => reset()

  return (
    <Box>
      <Flex alignItems={'center'}>
        <Box position={'relative'} ml={'5px'}>
          <NavigateBackButton onClick={() => setOpenedTemplates(false)} />
        </Box>
        <Box ml={'8px'} py={2}>
          {isOpen ? (
            <Button
              position={'relative'}
              size={'xs'}
              display={'flex'}
              alignItems={'center'}
              onClick={() => onToggle()}
            >
              <Box fontSize={'18px'}>
                <HiMiniXMark />
              </Box>
              <Text position={'relative'}>Отмена</Text>
            </Button>
          ) : (
            <Button
              position={'relative'}
              size={'xs'}
              bg="blue.700"
              color="white"
              display={'flex'}
              alignItems={'center'}
              onClick={() => onToggle()}
              _hover={{
                bg: 'blue.600',
              }}
            >
              <Box fontSize={'18px'} mr={'5px'}>
                <HiMiniPlusCircle />
              </Box>
              <Text position={'relative'}>Добавить шаблон</Text>
            </Button>
          )}
        </Box>
      </Flex>
      <Box
        position="relative"
        zIndex="1"
        boxShadow="0px 4px  10px rgba(0, 0, 0, 0.12)"
        borderBottom={'1px solid #eeeeee'}
        borderTop={'1px solid #319795'}
      >
        <Collapse in={isOpen} animateOpacity style={{ overflow: 'inherit' }}>
          <Flex borderBottom={'1px solid #dfe0eb'} flexDir={'column'}>
            <Box p={'10px'}>
              <TextTitle size={'small'}>
                Добавление новой зоны деятельности ГП
              </TextTitle>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box w={'900px'} p={2} m={2} border={'1px solid #dfe0eb'}>
                <FormInputBlock
                  titleWidth={'200px'}
                  allowEdit={true}
                  title={'Название шаблона:'}
                  edit={true}
                  value={''}
                >
                  <FormInputControl
                    name="template_name"
                    placeholder="Наименование"
                    type="text"
                    {...commonInputProps}
                  />
                </FormInputBlock>
                <Box mt={'10px'} />

                <SimpleGrid columns={1} spacing={3}>
                  {itemsFields.map((field, index) => {
                    const availableActivityAreas = getAvailableActivityAreas(
                      items[index].file_type,
                      index,
                    )

                    const availableReportTypes = getAvailableReportTypes(
                      items[index].activity_zone,
                      index,
                    )

                    return (
                      <Box key={field.id}>
                        <Grid templateColumns="1fr 1fr 30px" columnGap={'5px'}>
                          <FormInputBlock
                            titleWidth={'150px'}
                            title={'Тип файла'}
                            allowEdit={true}
                            edit={true}
                            topTitle
                          >
                            <SelectInputForm
                              placeholder="Тип файла"
                              data={availableReportTypes}
                              name={`items.${index}.file_type`}
                              isClearable={true}
                              getOptionValue={(option) =>
                                option ? option.value : ''
                              }
                              {...commonInputProps}
                            />
                          </FormInputBlock>
                          <FormInputBlock
                            titleWidth={'200px'}
                            title={'Зона деятельности'}
                            allowEdit={true}
                            edit={true}
                            topTitle
                          >
                            <SelectInputForm
                              isDisabled={!items[index].file_type}
                              isReadOnly={!items[index].file_type}
                              placeholder="Зона деятельности"
                              data={availableActivityAreas}
                              name={`items.${index}.activity_zone`}
                              isClearable={true}
                              getOptionValue={(option) =>
                                option ? option.value : ''
                              }
                              {...commonInputProps}
                            />
                          </FormInputBlock>

                          <Flex
                            w="100%"
                            justifyContent={'flex-end'}
                            alignItems={'end'}
                            pb={'7px'}
                          >
                            <Flex
                              w={'100%'}
                              justifyContent={'center'}
                              ml="15px"
                              className="icon-delete"
                              fontSize="20px"
                              cursor="pointer"
                              color="#c2c2c2"
                              _hover={{
                                cursor: 'pointer',
                                color: '#bc343f',
                              }}
                              onClick={() => removeItem(index)}
                            >
                              <HiOutlineArchiveBoxXMark />
                            </Flex>
                          </Flex>
                        </Grid>
                      </Box>
                    )
                  })}
                </SimpleGrid>
                <Box mt="15px">
                  <Button
                    w={'fit-content'}
                    mr="10px"
                    position={'relative'}
                    size={'xs'}
                    bg="blue.700"
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={() =>
                      appendItem({ file_type: '', activity_zone: '' })
                    }
                    isDisabled={disableAddButton}
                    _hover={{
                      bg: 'blue.600',
                    }}
                  >
                    <Box fontSize={'18px'} mr={'5px'}>
                      <HiMiniPlusCircle />
                    </Box>
                    <Text position={'relative'}>Добавить файл</Text>
                  </Button>
                </Box>
                {isDirty && (
                  <Box mt={'15px'}>
                    <SubmitCancelButtons
                      isDirty={isDirty}
                      isUpdating={isLoading}
                      handleCancel={handleResetForm}
                      isDisabled={!isValid || itemsFields.length === 0}
                    />
                  </Box>
                )}
              </Box>
            </form>
          </Flex>
        </Collapse>
      </Box>
    </Box>
  )
}
