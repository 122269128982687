import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'

export const PriceMonitoringPage = () => {
  return (
    <Tabs>
      <TabList p={'10px'} pb={0}>
        <Tab>
          <Text fontSize={'18px'}>Импорт файлов</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Загрузка с АТС</Text>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding={0}>Импорт файлов</TabPanel>
        <TabPanel padding={0}>Загрузка с АТС</TabPanel>
      </TabPanels>
    </Tabs>
  )
}
