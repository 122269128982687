import { Dispatch, useCallback, useEffect, useMemo, useState } from 'react'
import { map, transform, isObject, omit } from 'lodash'
import { Box, Button, Flex, Text } from '@chakra-ui/react'

import { GetTransServicesData } from 'shared/models'
import { EmptyDataMessage } from 'shared/ui'

import {
  TransFSKTableType,
  TransTableType,
} from 'widgets/prices/transServise/types/types'

import { TransServiceOnePartTable } from './TransServiceOnePartTable/TransServiceOnePartTable'
import { TransServiceTwoPartTable } from './TransServiceTwoPartTable/TransServiceTwoPartTable'
import { FlattenedTransServicesData } from '../types/types'
import { useUpdateTransService } from '../models/services/editTransService'
import { tableDataSignal, isDataChangedSignal } from '../signals/signals'

// misc
import { MdOutlineSave } from 'react-icons/md'
import { HiMiniXMark, HiOutlinePencilSquare } from 'react-icons/hi2'

interface EditTransServiceProps {
  data: GetTransServicesData[]
  downloadReportSlot: React.ReactNode
  year: string
  subject_id: string
  transTableType: TransTableType
  setTransTableType: Dispatch<React.SetStateAction<TransTableType>>
}

function revertDataFormat(flattenedData) {
  return flattenedData.map((item) => {
    return {
      single_rate: {
        vn: item.single_rate_vn,
        sn1: item.single_rate_sn1,
        sn2: item.single_rate_sn2,
        nn: item.single_rate_nn,
      },
      two_rate_tl: {
        vn: item.two_rate_tl_vn,
        sn1: item.two_rate_tl_sn1,
        sn2: item.two_rate_tl_sn2,
        nn: item.two_rate_tl_nn,
      },
      two_rate_net: {
        vn: item.two_rate_net_vn,
        sn1: item.two_rate_net_sn1,
        sn2: item.two_rate_net_sn2,
        nn: item.two_rate_net_nn,
      },
      month: item.month,
      document_details: item.document_details,
      added_at: item.added_at,
      user_name: item.user_name,
    }
  })
}

interface ButtonConfig {
  label: string
  value: TransTableType | TransFSKTableType
  borderRadius: {
    borderLeftRadius?: string
    borderRightRadius?: string
    borderRight?: string
  }
}

export const EditTransService: React.FC<EditTransServiceProps> = ({
  subject_id,
  year,
  data,
  downloadReportSlot,
  transTableType,
  setTransTableType,
}): React.ReactElement => {
  const [editMode, setEditMode] = useState<boolean>(false)

  const { mutate: updateTransService, isLoading } = useUpdateTransService()

  const transTableTypeButtons: ButtonConfig[] = [
    {
      label: 'Одноставочный',
      value: TransTableType.oneParts,
      borderRadius: { borderLeftRadius: '5px', borderRight: 'none' },
    },
    {
      label: 'Двухставочный',
      value: TransTableType.twoParts,
      borderRadius: { borderRightRadius: '5px' },
    },
  ]

  const flattenAndSanitizeData = (
    data: GetTransServicesData[],
  ): FlattenedTransServicesData[] => {
    return map(data, (item) =>
      transform(
        item,
        (result, value, key) => {
          if (isObject(value)) {
            transform(value, (subResult, subValue, subKey) => {
              if (subValue === null) {
                result[`${key}_${subKey}`] = ''
              } else if (
                typeof subValue === 'string' &&
                /^\d+(\.\d+)?$/.test(subValue)
              ) {
                result[`${key}_${subKey}`] = (subValue as string).replace(
                  '.',
                  ',',
                )
              } else result[`${key}_${subKey}`] = subValue
            })
          } else {
            if (value === null) result[key] = ''
            else if (typeof value === 'string' && /^\d+(\.\d+)?$/.test(value)) {
              result[key] = value.replace('.', ',')
            } else result[key] = value
          }
        },
        {} as FlattenedTransServicesData,
      ),
    )
  }

  const sanitizedData = useMemo(() => {
    return flattenAndSanitizeData(data)
  }, [data])

  useEffect(() => {
    setTableData(sanitizedData as FlattenedTransServicesData[])
    tableDataSignal.value = sanitizedData as FlattenedTransServicesData[]
  }, [sanitizedData])

  const [tableData, setTableData] = useState(
    sanitizedData as FlattenedTransServicesData[],
  )

  const handleCancelChanges = useCallback(() => {
    setTableData(sanitizedData as FlattenedTransServicesData[])
    setEditMode(false)
    isDataChangedSignal.value = false
  }, [sanitizedData])

  const toggleEditMode = () => setEditMode(!editMode)

  const saveChanges = useCallback(() => {
    if (!isDataChangedSignal.value) {
      setEditMode(false)
      return
    }
    setTableData(tableDataSignal.value)

    const newData = map(tableDataSignal.value, (item) =>
      transform(item, (result, value, key) => {
        result[key] =
          typeof value === 'string' && value.includes(',')
            ? value.replace(',', '.')
            : value === ''
            ? null
            : value
      }),
    )

    const convertedData = newData.filter(
      (item: FlattenedTransServicesData) => item.edited,
    )

    const dataToSend = convertedData.map((item) =>
      omit(item as FlattenedTransServicesData, [
        'edited',
        'isEdited',
        'user_name',
        'added_at',
      ]),
    )

    const revertedData = revertDataFormat(dataToSend)

    updateTransService({
      data: revertedData,
      date_period: year,
      subject_id: subject_id,
      successAction: () => {
        isDataChangedSignal.value = false
        setEditMode(false)
      },
    })
  }, [tableData])

  const editTransServiceTableProps = useMemo(
    () => ({
      tableData,
      editMode,
      saveChanges,
      toggleEditMode,
    }),
    [tableData, editMode, saveChanges, toggleEditMode],
  )

  const renderButton = (
    button: ButtonConfig,
    transTableType: TransTableType,
    setTransTableType: React.Dispatch<
      React.SetStateAction<TransTableType | TransFSKTableType>
    >,
  ) => (
    <Button
      key={button.label}
      w={'100px'}
      size="xs"
      borderRadius={0}
      bg={transTableType === button.value ? '#2d70af' : '#f8f8f8'}
      color={transTableType === button.value ? 'white' : 'black'}
      _hover={{ bg: '#eeeeee', color: 'black' }}
      onClick={() => setTransTableType(button.value)}
      {...button.borderRadius}
      padding={'5px 65px!important'}
    >
      {button.label}
    </Button>
  )

  return (
    <Box>
      {data && (
        <Box p={'17px'} pt={'0px'}>
          {Array.isArray(data) && data.length === 0 ? (
            <EmptyDataMessage />
          ) : (
            <>
              <Flex mb={'10px'} w={'100%'}>
                <Flex
                  justifyContent={'space-between'}
                  w={'100%'}
                  alignItems={'flex-end'}
                >
                  <Box ml={'-17px'}>
                    {transTableTypeButtons.map((button) =>
                      renderButton(button, transTableType, setTransTableType),
                    )}
                  </Box>

                  <Flex mt={'5px'} mr={'-25px'}>
                    {editMode && (
                      <Button
                        mr={'5px'}
                        size={'xs'}
                        isLoading={isLoading}
                        isDisabled={false}
                        bg="teal.400"
                        color="white"
                        display={'flex'}
                        alignItems={'center'}
                        onClick={saveChanges}
                        _hover={{
                          bg: 'teal.500',
                        }}
                      >
                        <Box fontSize={'22px'}>
                          <MdOutlineSave />
                        </Box>
                        Сохранить
                      </Button>
                    )}

                    {editMode ? (
                      <Button
                        mr={'5px'}
                        position={'relative'}
                        size={'xs'}
                        display={'flex'}
                        alignItems={'center'}
                        onClick={handleCancelChanges}
                        isDisabled={null}
                        isLoading={null}
                      >
                        <Box fontSize={'18px'}>
                          <HiMiniXMark />
                        </Box>
                        <Text position={'relative'}>Отмена</Text>
                      </Button>
                    ) : (
                      <Button
                        mr="5px"
                        position={'relative'}
                        size={'xs'}
                        color="white"
                        display={'flex'}
                        alignItems={'center'}
                        onClick={() => setEditMode(true)}
                        colorScheme="blue"
                        isDisabled={null}
                        isLoading={null}
                      >
                        <Box fontSize={'22px'} mr={'5px'}>
                          <HiOutlinePencilSquare />
                        </Box>
                        <Text position={'relative'}>Редактировать</Text>
                      </Button>
                    )}
                    {downloadReportSlot && downloadReportSlot}
                  </Flex>
                </Flex>
              </Flex>
              <Box mt="20px">
                {transTableType === TransTableType.oneParts && (
                  <TransServiceOnePartTable {...editTransServiceTableProps} />
                )}
                {transTableType === TransTableType.twoParts && (
                  <TransServiceTwoPartTable {...editTransServiceTableProps} />
                )}
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  )
}
