import React from 'react'
import { Text, Flex } from '@chakra-ui/react'
import { ListView } from 'shared/ui'

interface PriceZoneItemProps {
  day_zone: string
  price_category_id: string
  code_id: number
}

export const DayZonesItem: React.FC<PriceZoneItemProps> = (
  props,
): JSX.Element => {
  const { code_id, day_zone } = props

  return (
    <ListView.Stacks>
      <Flex alignItems={'center'} ml={'5px'}>
        <Text w={'35px'} fontSize={'sm'}>
          {code_id}
        </Text>

        <Text fontSize={'sm'} textAlign={'left'} maxW={'4xl'}>
          {day_zone}
        </Text>
      </Flex>
    </ListView.Stacks>
  )
}
