import { useCallback, useEffect, useMemo, useState } from 'react'
import { map, transform, omit } from 'lodash'
import { Box, Button, Flex, Text } from '@chakra-ui/react'

import { GetTransServicesFSKLossesData } from 'shared/models'
import { EmptyDataMessage } from 'shared/ui'

import { TransServiceFSKLossesTable } from './LossesTable/TransServiceFSKLossesTable'
import { isDataChangedSignal, tableDataSignal } from '../signals/signals'
import { useUpdateTransServiceFSKLosses } from '../models/editTransService'

// misc
import { MdOutlineSave } from 'react-icons/md'
import { HiMiniXMark, HiOutlinePencilSquare } from 'react-icons/hi2'

interface EditTransServiceFSKProps {
  data: GetTransServicesFSKLossesData[]
  downloadReportSlot: React.ReactNode
  year: string
  subject_id: string
}

export const EditTransServiceFSLosses: React.FC<EditTransServiceFSKProps> = ({
  year,
  data,
  subject_id,
  downloadReportSlot,
}): React.ReactElement => {
  const [editMode, setEditMode] = useState<boolean>(false)

  const { mutate: updateTransServiceFSKLosses, isLoading } =
    useUpdateTransServiceFSKLosses()

  const sanitizedData = useMemo(
    () =>
      map(data, (item) =>
        transform(item, (result, value, key) => {
          if (value === null) result[key] = ''
          else if (typeof value === 'string' && /^\d+(\.\d+)?$/.test(value))
            result[key] = value.replace('.', ',')
          else result[key] = value
        }),
      ),
    [data],
  )

  useEffect(() => {
    setTableData(sanitizedData as GetTransServicesFSKLossesData[])
    tableDataSignal.value = sanitizedData as GetTransServicesFSKLossesData[]
  }, [sanitizedData])

  const [tableData, setTableData] = useState(
    sanitizedData as GetTransServicesFSKLossesData[],
  )

  const editTransServiceTableProps = useMemo(
    () => ({
      tableData,
      editMode,
    }),
    [tableData, editMode],
  )

  const handleCancelChanges = useCallback(() => {
    setTableData(sanitizedData as GetTransServicesFSKLossesData[])
    setEditMode(false)
    isDataChangedSignal.value = false
  }, [sanitizedData])

  const saveChanges = useCallback(() => {
    if (!isDataChangedSignal.value) {
      setEditMode(false)
      return
    }
    setTableData(tableDataSignal.value)

    const newData = map(tableDataSignal.value, (item) =>
      transform(item, (result, value, key) => {
        result[key] =
          typeof value === 'string' && value.includes(',')
            ? value.replace(',', '.')
            : value === ''
            ? null
            : value
      }),
    )

    const convertedData = newData.filter(
      (item: GetTransServicesFSKLossesData) => item.edited,
    )

    const dataToSend = convertedData.map((item) =>
      omit(item as GetTransServicesFSKLossesData, [
        'edited',
        'isEdited',
        'user_name',
        'added_at',
      ]),
    )

    updateTransServiceFSKLosses({
      data: dataToSend as GetTransServicesFSKLossesData[],
      date_period: year,
      subject_id,
      successAction: () => {
        isDataChangedSignal.value = false
        setEditMode(false)
      },
    })
  }, [tableData])

  return (
    <Box>
      {data && (
        <Box p={'17px'} pt={'0px'} maxW={'1400px'}>
          {Array.isArray(data) && data.length === 0 ? (
            <EmptyDataMessage />
          ) : (
            <>
              <Flex mb={'10px'} w={'100%'}>
                <Flex justifyContent={'flex-end'} w={'100%'}>
                  <Flex mt={'5px'} mr={'-25px'}>
                    {editMode && (
                      <Button
                        mr={'5px'}
                        size={'xs'}
                        isLoading={isLoading}
                        isDisabled={false}
                        bg="teal.400"
                        color="white"
                        display={'flex'}
                        alignItems={'center'}
                        onClick={saveChanges}
                        _hover={{
                          bg: 'teal.500',
                        }}
                      >
                        <Box fontSize={'22px'}>
                          <MdOutlineSave />
                        </Box>
                        Сохранить
                      </Button>
                    )}
                    {editMode ? (
                      <Button
                        mr={'5px'}
                        position={'relative'}
                        size={'xs'}
                        display={'flex'}
                        alignItems={'center'}
                        onClick={handleCancelChanges}
                        isDisabled={null}
                        isLoading={null}
                      >
                        <Box fontSize={'18px'}>
                          <HiMiniXMark />
                        </Box>
                        <Text position={'relative'}>Отмена</Text>
                      </Button>
                    ) : (
                      <Button
                        mr="5px"
                        position={'relative'}
                        size={'xs'}
                        color="white"
                        display={'flex'}
                        alignItems={'center'}
                        onClick={() => setEditMode(true)}
                        colorScheme="blue"
                        isDisabled={isLoading}
                        isLoading={isLoading}
                      >
                        <Box fontSize={'22px'} mr={'5px'}>
                          <HiOutlinePencilSquare />
                        </Box>
                        <Text position={'relative'}>Редактировать</Text>
                      </Button>
                    )}
                    {downloadReportSlot && downloadReportSlot}
                  </Flex>
                </Flex>
              </Flex>
              <Box mt="20px">
                <TransServiceFSKLossesTable {...editTransServiceTableProps} />
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  )
}
