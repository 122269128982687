export const INDEX = ''
export const SETTINGS = 'settings'
export const TUNING = 'tuning'
export const COMPANIES = 'companies'
export const CREATE = 'create'
export const TEST = 'test'
export const USERS = 'users'
export const NOT_FOUND = 'not-found'
export const ACCESSES = 'accesses'
export const REGISTRATION = 'set-password'
export const DIRECTORY = 'directory'
export const CALENDAR = 'calendar'
export const REGIONS = 'regions'
export const PRICE_CATEGORIES = 'price-categories'
export const COUNTER_PARTIES = 'counter-parties'
export const AGREEMENT = 'agreement'
export const PROFILE = 'profile'
export const REFERENCE_BOOKS = 'reference_books'
export const PRICES = 'prices'
export const MONITORING = 'monitoring'
export const IMPORT_DATA = 'import_data'
export const PRICE_DATA = 'price_data'
export const TARIFFS = 'tariffs'
export const GP_DATA = 'gp_data'

const getRoute = (pages: string[] | string): string => {
  if (pages instanceof Array) return `/${pages.join('/')}`
  return `/${pages}`
}

export const HOME_ROUTE = getRoute([INDEX])

/**
 * settings
 */
export const SETTINGS_ROUTE = getRoute([SETTINGS])

/**
 * prices
 */
export const PRICES_ROUTE = getRoute([PRICES])

/**
 * settings
 */
export const SETTINGS_USERS_ROUTE = getRoute([SETTINGS, USERS])

/**
 * directory
 */
export const REFERENCE_BOOKS_ROUTE = getRoute([REFERENCE_BOOKS])

/**
 * not-found
 */
export const NOT_FOUND_ROUTE = getRoute([NOT_FOUND])

/**
 * settings/companies
 */
export const COMPANIES_ROUTE = getRoute([SETTINGS, COMPANIES])

/**
 * settings/companies
 */
export const CREATE_COMPANY_ROUTE = getRoute([SETTINGS, COMPANIES, CREATE])

/**
 * settings/profile
 */
export const USER_PROFILE_ROUTE = getRoute([SETTINGS, PROFILE])

/**
 * settings/users
 */
export const USERS_ROUTE = getRoute([SETTINGS, USERS])

/**
 * settings/accesses
 */
export const ACCESSES_ROUTE = getRoute([SETTINGS, ACCESSES])

/**
 * settings/tuning
 */
export const TUNING_ROUTE = getRoute([SETTINGS, TUNING])

/**
 * prices/price_data
 */
export const PRICE_DATA_ROUTE = getRoute([PRICES, PRICE_DATA])

/**
 * registration
 */
export const REGISTRATION_ROUTE = getRoute([REGISTRATION, ':registrationToken'])
