import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import moment from 'moment'

export interface ImportATCFormData {
  template?: string
  period_from?: Date
  period_to?: Date
  few_periods?: boolean
}

const SCHEMA_VALIDATION = yup.object().shape({})

export const useImportATCDataForm = () => {
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
    watch,
    setError,
  } = useForm<ImportATCFormData>({
    resolver: yupResolver(SCHEMA_VALIDATION),
    mode: 'onChange',
    defaultValues: {
      template: '',
      period_from: null,
      period_to: null,
    },
  })

  const watchedFields = watch()

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
    resetField,
    setValue,
    watchedFields,
    setError,
    watch,
  }
}
